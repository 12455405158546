import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, Grid, Card, Avatar } from "@material-ui/core";
import Header from "../../components/header";
import { CSVLink } from "react-csv";
import AddCircleOutlinedIcon from "@mui/icons-material/AddCircleOutlined";
import DownloadIcon from "@mui/icons-material/Download";
import CardContent from "@mui/material/CardContent";
import { damageDevices, installedDevices, lostDevices, removeDevices, stockDevices, totalDevices, vehicleImage2 as vehicle2, workingDevices } from "../../assets/index";
import { vehicleImage3 as vehicle3 } from "../../assets/index";
import { vehicleImage5 as vehicle5 } from "../../assets/index";
import { useHistory } from "react-router";
import _, { filter } from "lodash";
import * as Fields from "../../sharedComponents";
import { useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import * as CONFIG from "../../config/GlobalConstants";
import * as Components from "../../sharedComponents";
import AlertMessage from "../../components/alertmessage/AlertMessage";
import CircularLoading from "../../components/loader/circularLoading";
import { AssignDeviceToTruck } from "./assignDevice";
import { valHooks } from "jquery";
import { TransferTruck } from "./transferDevice";
import { RemoveDevice } from "./removeDevice";
import { DamageDevice } from "./damageDevice";
import { MoveDeviceToSubaccount } from "./moveDeviceToSubaccount";
import { LostDevice } from "./lostDevice";
import { iterateObserversSafely } from "@apollo/client/utilities";
import MultiselectFields from "../../sharedComponents/antd/MultiselectFields";
import * as XLSX from 'xlsx';
import { AssignSimToDevice } from "./assignSim";
import { SubscriptionOfDevice } from "./subscription";
// import moment from "moment";
// import { CSVLink } from "react-csv";
// import CardContent from "@mui/material/CardContent";

const useStyles = makeStyles((theme) => ({
  header_buttons: {
    padding: "0px 15px",
  },
  alertBox: {
    padding: "10px 20px",
  },
  client_button: {
    background: "#649B42",
    textTransform: "none",
    color: "#FFFFFF",
    // marginRight: "15px",
    padding: "8px 15px",
    "&:hover": {
      background: "#649B42",
    },
  },
  history_button: {
    background: "#649B42",
    textTransform: "none",
    color: "#FFFFFF",
    padding: "8px 15px",
    "&:hover": {
      background: "#649B42",
    },
  },
  filter_buttons: {
    textAlign: "right",
    padding: "0px 15px",
  },
  search_button: {
    padding: "6px 15px",
    marginRight: "12px",
  },
  clear_button: {
    padding: "6px 15px",
    background: "#D3710F0D",
    border: "1px solid #D3710F",
    color: "#D3710F",
    "&:hover": {
      background: "#D3710F0D",
    },
    marginTop:'30px'
  },
  export_button: {
    padding: "8px 15px",
    background: "#366E93",
    textTransform: "none",
    color: "#FFF",
    "&:hover": {
      background: "#366E93",
    },
    marginRight: 10,
  },
  contract_button: {
    background: "#D08B1D",
    textTransform: "none",
    color: "#FFFFFF",
    padding: "8px 15px",
    marginRight: theme.spacing(2),
    "&:hover": {
      background: "#D08B1D",
    },
  },
  route_btn: {
    background: "#F23838",
    textTransform: "none",
    color: "#FFFFFF",
    padding: "8px 15px",
    marginRight: theme.spacing(2),
    "&:hover": {
      background: "#F23838",
    },
  },
}));

export let MyDevice = () => {
  const columns = [
    {
      id: "sNo",
      disablePadding: true,
      label: "S.NO",
      checked: true,
    },
    {
      id: "clientName",
      disablePadding: true,
      label: "CLIENT NAME",
      checked: true,
    },
    {
      id: "deviceImei",
      disablePadding: true,
      label: "DEVICE IMEI",
      checked: true,
    },
    {
      id: "deviceInstallDate",
      disablePadding: true,
      label: "DEVICE INSTALL DATE",
      checked: true,
    },
    {
      id: "subscriptionDate",
      disablePadding: true,
      label: "SUBSCRIPTION DATE",
      checked: true,
    },
    {
      id: "subscriptionExpDate",
      disablePadding: true,
      label: "SUBSCRIPTION EXP DATE",
      checked: true,
    },
    {
      id: "deviceStatus",
      disablePadding: true,
      label: "DEVICE STATUS",
      checked: true,
    },
    {
      id: "simNum",
      disablePadding: true,
      label: "SIM NUMBER",
      checked: true,
    },
    {
      id: "vehicleRegiNum",
      disablePadding: true,
      label: "REGISTRATION NUMBER",
      checked: true,
    },
    {
      id: "actions",
      disablePadding: true,
      label: "ACTIONS",
      checked: true,
    },
  ];
  const [headRows, setHeadRows] = useState(columns);
  const [cols, setCols] = useState(columns);
  const [tableData, setTableData] = useState([]);
  const [assign, setAssign] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const [remove, setRemove] = useState(false);
  const [damage, setDamage] = useState(false);
  const [move, setMoved] = useState(false);
  const [lost, setLost] = useState(false);
  const [assignSim, setAssignSim] = useState(false);
  const [subscription, setSubscription] = useState(false);
  const [devicesData, setDevicesData] = useState();
  const [pagination, setPagination] = useState({
    current: CONFIG.PAGINATION.current,
    pageSize: CONFIG.PAGINATION.pageSize,
  });
  const [spinner, setSpinner] = useState(false);
  let Service = global.service;
  const [total, setTotal] = useState(0);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const filterFields = {
    deviceStatus: {
      name: "deviceStatus",
      label: "Device Status",
      value: { label: "", value: "" },
      options: [
        { label: "Active", value: "1" },
        { label: "InActive", value: "0" },
      ],
      allowClear: true,
      topLabel: true,
    },
    deviceImei: {
      name: "deviceImei",
      label: "Device Imei",
      value: { label: "", value: "" },
      options: [],
      allowClear: true,
      topLabel: true,
    },
    dateRange: {
      name: "dateRange",
      label: "Date Range",
      topLabel: true,
      value: [],
      allowClear: true,
    },
    companyName: {
      name: "companyName",
      label: "Company Name",
      value: { label: "", value: "" },
      options: [],
      allowClear: true,
      topLabel: true,
    },
    vehicleslist: {
      name: "vehicleslist",
      label: "Vehicles List",
      options: [],
      value: [],
      actualValue: [],
      data: [],
      topLabel: true,
      placeholder: "Select Vehicle",
      allowClear : true
    },
  };
  const classes = useStyles();
  const history = useHistory();
  const [optionsList, setOptionsList] = useState([]);
  const [filters, setFilters] = useState(_.cloneDeep(filterFields));
  const [assignData, setAssignData] = useState();
  const [type, setType] = useState("isWorking");
  const [csvData, setCsvData] = useState([]);
  let csvlink = useRef();
  const clearHandler = () => {
    window.location.reload();
  };
  const autoCompleteChangeHandler = async (value, name) => {
    let newFilters = _.cloneDeep(filters);

    if (value) {
      // let newFilters = _.cloneDeep(filters);
      if (name == "companyName") {
        newFilters[name]["value"] = value;
        await Service.getDevicesByCompanyId(value?.value)
          .then((res) => {
            let companies = res.data.map((each) => {
              return { label: each.deviceImei, value: each.id };
            });
            newFilters.deviceImei.options = companies;
          })
          .catch((e) => {
            console.log("Error.......", e);
          });
          await Service.getVehiclesByCompanyId(value?.value)
          .then((res) => {
            if (res.data.length > 0) {
              let truckOptions = res.data.map((each) => {
                return { label: each.regi_no, value: each.id };
              });
              let listOptions = res.data.map((item) => {
                return { id: item.id, name: item.regi_no };
              });
              newFilters.vehicleslist.options = truckOptions;
              setOptionsList(listOptions);
            }
          })
          .catch((e) => {
            console.log("Error......", e);
          });
        setFilters(newFilters);
      } else {
        newFilters[name]["value"] = value;
        setFilters(newFilters);
      }
      setPagination({ ...pagination, current: 0 });
      renderMyDevicesData(
        {
          skip: pagination.current * pagination.pageSize,
          limit: pagination.pageSize,
          companyId: newFilters?.companyName?.value?.value,
          deviceId: newFilters?.deviceImei?.value?.value,
          status: newFilters?.deviceStatus?.value?.value,
          truckIds: newFilters?.vehicleslist?.value,
          type: type,
        },
        columns
      );
    }
  };
  const renderMyDevicesData = (dataObj, cols) => {
    setAlertData({
      open: false,
      severity: "",
      message: "",
    });
    // dataObj.type = type
    setSpinner(true);
    Service.getMyDevicesList(dataObj)
      .then((res) => {
        if (res.status == false) {
          setDevicesData(res);
          setSpinner(false);
          setAlertData({
            open: true,
            severity: CONFIG.ALERT_SEVERITY.error,
            message: res.message,
          });
          setTableData([]);
          setTotal(0);
        } else if (res.data.length > 0) {
          setDevicesData(res);
          let checkedCols = cols.filter((col) => col.checked === true);
          setHeadRows(checkedCols);
          let data = res?.data?.map((deviceData, i) => {
            return {
              ...(checkedCols.find((data) => data.id === "sNo")
                ? {
                    sNo: <div style={{ textAlign: "center" }}>{dataObj.skip ? dataObj.skip + i + 1 : i + 1 }</div>,
                  }
                : {}),
              ...(checkedCols.find((data) => data.id === "clientName")
                ? {
                    clientName: (
                      <div style={{ textAlign: "center" }}>
                        {deviceData?.companyName
                          ? deviceData?.companyName
                          : "--"}
                      </div>
                    ),
                  }
                : {}),
              ...(checkedCols.find((data) => data.id === "deviceImei")
                ? {
                    deviceImei: (
                      <div style={{ textAlign: "center" }}>
                        {deviceData?.deviceImei ? deviceData?.deviceImei : "--"}
                      </div>
                    ),
                  }
                : {}),
              ...(checkedCols.find((data) => data.id === "deviceInstallDate")
                ? {
                    deviceInstallDate: (
                      <div style={{ textAlign: "center" }}>
                        {deviceData?.installDate
                          ? deviceData?.installDate
                          : "--"}
                      </div>
                    ),
                  }
                : {}),
              ...(checkedCols.find((data) => data.id === "subscriptionDate")
                ? {
                    subscriptionDate: (
                      <div style={{ textAlign: "center" }}>
                        {deviceData?.subscriptionDate
                          ? deviceData?.subscriptionDate
                          : "--"}
                      </div>
                    ),
                  }
                : {}),
              ...(checkedCols.find((data) => data.id === "subscriptionExpDate")
                ? {
                    subscriptionExpDate: (
                      <div style={{ textAlign: "center" }}>
                        {deviceData?.expiryDate ? deviceData?.expiryDate : "--"}
                      </div>
                    ),
                  }
                : {}),
              ...(checkedCols.find((data) => data.id === "deviceStatus")
                ? {
                    deviceStatus: (
                      <div style={{ textAlign: "center" }}>
                        {deviceData?.status
                          ? deviceData?.status == 1
                            ? "Active"
                            : "In Active"
                          : "--"}
                      </div>
                    ),
                  }
                : {}),
              ...(checkedCols.find((data) => data.id === "simNum")
                ? {
                    simNum: (
                      <div style={{ textAlign: "center" }}>
                        {deviceData?.simNum ? deviceData?.simNum : "--"}
                      </div>
                    ),
                  }
                : {}),
              ...(checkedCols.find((data) => data.id === "vehicleRegiNum")
                ? {
                    vehicleRegiNum: (
                      <div style={{ textAlign: "center" }}>
                        {deviceData?.regiNum ? deviceData?.regiNum : "--"}
                      </div>
                    ),
                  }
                : {}),
              actions: (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Components.DropDownMenu
                    options={[
                      { label: "Move to Subaccount", type: "button" },
                      { label: "View Details", value: "/deviceViewDetails" },
                      { label: "Assign Sim", type: "button" },
                      { label: "Assign Truck", type: "button" },
                      { label: "Transfer", type: "button" },
                      { label: "Damage", type: "button" },
                      { label: "Remove", type: "button" },
                      { label: "Lost", type: "button" },
                      { label: "Subscription", type: "button" },
                    ]}
                    data={deviceData}
                    menuStyle={{ textAlign: "center" }}
                    buttonClickHandler={(val) =>
                      buttonClickHandler(val, deviceData)
                    }
                  />
                </div>
              ),
            };
          });

          if (dataObj.type == "isWorking") {
            setTotal(res.usedDevicesCount);
          } else if (dataObj.type == "isTotal") {
            setTotal(res.totalCount);
          } else if (dataObj.type == "isInstall") {
            setTotal(res.installedDevices);
          } else if (dataObj.type == "isStock") {
            setTotal(res.stockDevices);
          } else if (dataObj.type == "isDamage") {
            setTotal(res.damageDevices);
          } else if (dataObj.type == "isLost") {
            setTotal(res.lostDevices);
          } else if (dataObj.type == "isRemoved") {
            setTotal(res.removeDevices);
          }
          
          // setDashboardCounts(res.allCardsCount);
          setTableData(data);
          setSpinner(false);
        } else if (res.deliveryOrders.length === 0) {
          setSpinner(false);
          setTableData([]);
          setTotal(0);
        }
      })
      .catch((error) => {
        setSpinner(false);
        setAlertData({
          open: true,
          severity: CONFIG.ALERT_SEVERITY.error,
          message:
            error.response && error.response.data
              ? error.response.data.message
              : "Something went wrong!",
        });
      });
  };
  const handleClickForCards = (props) => {
    let dataObj = {
      skip: 0,
      limit: 25,
      companyId: filters?.companyName?.value?.value,
      deviceId: filters?.deviceImei?.value?.value,
      status: filters?.deviceStatus?.value?.value,
      truckIds: filters?.vehicleslist?.value,
    };

    if (props == "isWorking") {
      setType("isWorking");
      setPagination({
        current: CONFIG.PAGINATION.current,
        pageSize: CONFIG.PAGINATION.pageSize,
      });

      dataObj["type"] = "isWorking";
      renderMyDevicesData(dataObj, cols);
    } else if (props == "isTotal") {
      setType("isTotal");
      setPagination({
        current: CONFIG.PAGINATION.current,
        pageSize: CONFIG.PAGINATION.pageSize,
      });
      dataObj["type"] = "isTotal";
      renderMyDevicesData(dataObj, cols);
    } else if (props == "isInstall") {
      setType("isInstall");
      setPagination({
        current: CONFIG.PAGINATION.current,
        pageSize: CONFIG.PAGINATION.pageSize,
      });
      dataObj["type"] = "isInstall";
      renderMyDevicesData(dataObj, cols);
    } else if (props == "isStock") {
      setType("isStock");
      setPagination({
        current: CONFIG.PAGINATION.current,
        pageSize: CONFIG.PAGINATION.pageSize,
      });
      dataObj["type"] = "isStock";
      renderMyDevicesData(dataObj, cols);
    } else if (props == "isDamage") {
      setType("isDamage");
      setPagination({
        current: CONFIG.PAGINATION.current,
        pageSize: CONFIG.PAGINATION.pageSize,
      });
      dataObj["type"] = "isDamage";
      renderMyDevicesData(dataObj, cols);
    } else if (props == "isLost") {
      setType("isLost");
      setPagination({
        current: CONFIG.PAGINATION.current,
        pageSize: CONFIG.PAGINATION.pageSize,
      });
      dataObj["type"] = "isLost";
      renderMyDevicesData(dataObj, cols);
    } else if (props == "isRemoved") {
      setType("isRemoved");
      setPagination({
        current: CONFIG.PAGINATION.current,
        pageSize: CONFIG.PAGINATION.pageSize,
      });
      dataObj["type"] = "isRemoved";
      renderMyDevicesData(dataObj, cols);
    }
  };
  const buttonClickHandler = (value, deviceData) => {
    if (value.label === "Assign Truck") {
      setAssign(true);
    } else if (value.label == "Transfer") {
      setTransfer(true);
    } else if (value.label == "Damage") {
      setDamage(true);
    } else if (value.label == "Remove") {
      setRemove(true);
    } else if (value.label == "Lost") {
      setLost(true);
    } else if (value.label == "Move to Subaccount") {
      setMoved(true);
    }else if (value.label == "Assign Sim") {
      setAssignSim(true);
    }else if (value.label == "Subscription") {
      setSubscription(true);
    }
    

    setAssignData(deviceData);
    // Perform any additional actions based on the selected value here
  };
  const dateRangeChangeHandler = (value, name) => {
    let newFilters = _.cloneDeep(filters);
    let dataObj;
    if (value.startDate && value.endDate) {
      newFilters[name]["value"] = [
        new Date(moment(value.startDate, "YYYY-MM-DD 00:00:00")),
        new Date(moment(value.endDate, "YYYY-MM-DD 23:59:59")),
      ];
      setFilters(newFilters);
      // dataObj = {
      //   skip: pagination.current * pagination.pageSize,
      //   limit: pagination.pageSize,
      //   clientId: newFilters.client.value.value,
      //   type: type,
      //   contractId: newFilters.contract.value.value,
      //   doType: newFilters.doType.value.value,
      //   startDate: moment(new Date(newFilters.dateRange.value[0])).format(
      //     "YYYY-MM-DD 00:00:00"
      //   ),
      //   endDate: moment(new Date(newFilters.dateRange.value[1])).format(
      //     "YYYY-MM-DD 23:59:59"
      //   ),
      // };
    } else {
      newFilters[name]["value"] = [];
      setFilters(newFilters);
      // dataObj = {
      //   skip: pagination.current * pagination.pageSize,
      //   limit: pagination.pageSize,
      //   type: type,
      // };
    }
    // renderMyDevicesData(dataObj, cols);
  };

  const pageChangeHandler = (page) => {
    let fil = Object.values(filters).filter((fil) => fil.value);
    let dataObj;
    if (fil.length > 0) {
      let filters = fil.map((fil) => {
        switch (fil.name) {
          case "companyName":
            return { companyId: fil.value?.value };
          case "deviceImei":
            return { deviceId: fil.value?.value };
          case "deviceStatus":
            return { status: fil.value?.value };
            case "vehicleslist":
            return { truckIds: fil.value};
          default:
            return;
        }
      });
      filters = Object.assign({}, ...filters);
      dataObj = {
        skip: page * pagination.pageSize,
        limit: pagination.pageSize,
        type: type,
        // contractId: props.location.state.contractId,
        ...filters,
      };
    } else {
      dataObj = {
        skip: page * pagination.pageSize,
        limit: pagination.pageSize,
        type: type,
        // contractId: props.location.state.contractId,
      };
    }
    renderMyDevicesData(dataObj, cols);
    setPagination({ ...pagination, current: page });
  };
  const rowsPerPageChangeHandler = (rowsPerPage) => {
    renderMyDevicesData(
      {
        skip: pagination.current * rowsPerPage,
        limit: rowsPerPage,
        type: type,
        companyId: filters?.companyName?.value?.value,
        deviceId: filters?.deviceImei?.value?.value,
        status: filters?.deviceStatus?.value?.value,
        truckIds : filters?.vehicleslist?.value,
      },
      cols
    );
    setPagination({ ...pagination, pageSize: rowsPerPage });
  };
  const columnCheckHandler = (columns) => {
    setCols(columns);
    renderMyDevicesData(
      {
        skip: pagination.current * pagination.pageSize,
        limit: pagination.pageSize,
        type: type,
        companyId: filters?.companyName?.value?.value,
        deviceId: filters?.deviceImei?.value?.value,
        status: filters?.deviceStatus?.value?.value,
        truckIds : filters?.vehicleslist?.value,
        // ...(filters.doType.value ? { doType: filters.doType.value.value } : {}),
      },
      columns
    );
  };

  useEffect(async () => {
    let newField = _.cloneDeep(filters);
    await Service.getCompanies()
      .then((res) => {
        let companies = res.data.map((each) => {
          return { label: each.companyName, value: each.id };
        });
        newField.companyName.options = companies;
      })
      .catch((e) => {
        console.log("Error.......", e);
      });
    await Service.getDevicesByCompanyId(newField.companyName.value?.value)
      .then((res) => {
        let devices = res.data.map((each) => {
          return { label: each.deviceImei, value: each.id };
        });
        newField.deviceImei.options = devices;
      })
      .catch((e) => {
        console.log("Error.......", e);
      });
    setFilters(newField);
    renderMyDevicesData(
      {
        skip: pagination.current * pagination.pageSize,
        limit: pagination.pageSize,
        type: "isWorking",
        // contractId: props.location.state.contractId,
      },
      cols
    );
  }, []);
const [totalDevicesData,setResponseData] = useState([])
  const getDownloadData = () => {
    
      let dataObj = {
        type: type,
        companyId: filters?.companyName?.value?.value,
        deviceId: filters?.deviceImei?.value?.value,
        status: filters?.deviceStatus?.value?.value,
        truckIds : filters?.vehicleslist?.value,
        // ...(filters.doType.value ? { doType: filters.doType.value.value } : {}),
      }
      
      Service.getMyDevicesList(dataObj)
      .then((res) => {
        if(res.status){
          const wb = XLSX.utils.book_new();
          let totalDevicesData = res
          // setResponseData(res)
          if (type === 'isWorking' || type === 'isTotal') {
            let countHeaders;
            let countObject;
        
            if (type === 'isTotal') {
                countHeaders = [
                    "Installed Devices", "Stock Devices", "Damage Devices", "Lost Devices",
                    "Removed Devices", "Total Devices"
                ];
                countObject = [{
                    "Installed Devices": totalDevicesData.installedDevices,
                    "Stock Devices": totalDevicesData.stockDevices,
                    "Damage Devices": totalDevicesData.damageDevices,
                    "Lost Devices": totalDevicesData.lostDevices,
                    "Removed Devices": totalDevicesData.removeDevices,
                    "Total Devices": totalDevicesData.totalCount,
                }];
            } else {
                countHeaders = [
                    "Installed Devices", "Stock Devices", "Total Devices"
                ];
                countObject = [{
                    "Installed Devices": totalDevicesData.installedDevices,
                    "Stock Devices": totalDevicesData.stockDevices,
                    "Total Devices": totalDevicesData.usedDevicesCount,
                }];
            }
        
            const wsTotalCount = XLSX.utils.json_to_sheet(countObject, { header: countHeaders });
            XLSX.utils.sheet_add_aoa(wsTotalCount, [countHeaders], { origin: 'A1' });
            wsTotalCount['!cols'] = countHeaders.map(() => ({ width: 20 }));
            wsTotalCount['A1'].s = { fill: { fgColor: { rgb: "FF9999" } }, font: { bold: true } }; // Light red fill for headers
        
            // Add the sheet to the workbook
            XLSX.utils.book_append_sheet(wb, wsTotalCount, "Total Devices Count");
        }
        
        // Process data for the main devices sheet
        let data = totalDevicesData.data.map((item, i) => {
            return {
                Sno: `${i + 1}`, // Use backticks for template literals
                "Client Name": item.companyName,
                "Service Provider" : item.deviceProviderName,
                "Registration Number": item.regiNum ? item.regiNum : '--',
                "Device IMEI Num": item.deviceImei,
                "Device Serial Num" : item.deviceSerialNum,
                "Device Install Date": item.installDate,
                "Device Install Month": moment(item.installDate, "MMM D, YYYY").format('MMMM'),
                "Device Status": item.deviceStatus == '1' ? 'Active' : 'In Active',
                "Subscription Type" : item.subscriptionType,
                "Subscription Date": item.subscriptionDate,
                "Subscription Expiry Date": item.expiryDate,
                "Sim Number": item.simNum,
                "Sim Activate Date": item.simActvateDate,
            };
        });
        const headers = [
            "Sno", "Client Name", "Service Provider", "Registration Number",
            "Device IMEI Num", "Device Serial Num", "Device Install Date",
            "Device Install Month", "Device Status", "Subscription Type",
            "Subscription Date", "Subscription Expiry Date", "Sim Number",
            "Sim Activate Date"
        ];
        
        const ws = XLSX.utils.json_to_sheet(data, { header: headers });
        XLSX.utils.sheet_add_aoa(ws, [headers], { origin: 'A1' });
        
        // Style the headers
        ws['!cols'] = headers.map(() => ({ width: 20 }));
        ws['A1'].s = { fill: { fgColor: { rgb: "FFFF00" } }, font: { bold: true } }; // Yellow fill and bold font for headers
        
        // Add the main devices sheet to the workbook
        XLSX.utils.book_append_sheet(wb, ws, "Total Devices");

        if(type == 'isWorking' || type == 'isTotal'){
          if(totalDevicesData.installedDevicesData.length > 0){
            const installedRow = ['Total Installed Devices Count', ` ${totalDevicesData.installedDevices}`];
            let installedDevices = totalDevicesData.installedDevicesData.map((item, i) => {
              let installDate = moment(item.installDate).format('MMM D, YYYY')
              let subscriptionDate = moment(item.subscriptionDate).format('MMM D, YYYY')
              let simActvateDate = moment(item.simActvateDate).format('MMM D, YYYY')
              let expiryDate = moment(item.expiryDate).format('MMM D, YYYY')
              return {
                Sno: `${i + 1}`,
                "Client Name": item.companyName,
                "Service Provider" : item.deviceProviderName,
                "Registration Number": item.regiNum ? item.regiNum : '--',
                "Device IMEI Num": item.deviceImei,
                "Device Serial Num" : item.deviceSerialNum,
                "Device Install Date": installDate,
                "Device Install Month": moment(installDate,"MMM D, YYYY").format('MMMM'),
                "Device Status": item.deviceStatus == '1' ? 'Active' : 'In Active',
                "Subscription Type" : item.subscriptionType,
                "Subscription Date": subscriptionDate,
                "Subscription Expiry Date": expiryDate,
                "Sim Number": item.simNum,
                "Sim Activate Date": simActvateDate,
              };
            });
            const wsInstall = XLSX.utils.json_to_sheet(installedDevices, { header: headers });
            XLSX.utils.sheet_add_aoa(wsInstall, [headers], { origin: 'A1' });
            wsInstall['!cols'] = headers.map(() => ({ width: 20 }));
            wsInstall['A1'].s = { fill: { fgColor: { rgb: "FF9999" } }, font: { bold: true } }; // Light red fill for headers
            // XLSX.utils.sheet_add_aoa(wsInstall, [installedRow], { origin: -1 });
            // wsInstall[`A${installedDevices.length + 2}`].s = { fill: { fgColor: { rgb: "FFCCCC" } }, font: { bold: true } }; // Light red fill and bold font for summary
            XLSX.utils.book_append_sheet(wb, wsInstall, "Installed Devices");
          }
          if(totalDevicesData.stockDevicesData.length > 0){
            const stockedRow = ['Total Installed Devices Count', ` ${totalDevicesData.stockDevices}`];
            let stockDevices = totalDevicesData.stockDevicesData.map((item, i) => {
              let installDate = moment(item.installDate).format('MMM D, YYYY')
              let subscriptionDate = moment(item.subscriptionDate).format('MMM D, YYYY')
              let simActvateDate = moment(item.simActvateDate).format('MMM D, YYYY')
              let expiryDate = moment(item.expiryDate).format('MMM D, YYYY')
              return {
                Sno: `${i + 1}`,
                "Client Name": item.companyName,
                "Service Provider" : item.deviceProviderName,
                "Registration Number": item.regiNum ? item.regiNum : '--',
                "Device IMEI Num": item.deviceImei,
                "Device Serial Num" : item.deviceSerialNum,
                "Device Install Date": installDate,
                "Device Install Month": moment(installDate,"MMM D, YYYY").format('MMMM'),
                "Device Status": item.deviceStatus == '1' ? 'Active' : 'In Active',
                "Subscription Type" : item.subscriptionType,
                "Subscription Date": subscriptionDate,
                "Subscription Expiry Date": expiryDate,
                "Sim Number": item.simNum,
                "Sim Activate Date": simActvateDate,
              };
            });
            const wsStock = XLSX.utils.json_to_sheet(stockDevices, { header: headers });
            XLSX.utils.sheet_add_aoa(wsStock, [headers], { origin: 'A1' });
            wsStock['!cols'] = headers.map(() => ({ width: 20 }));
            wsStock['A1'].s = { fill: { fgColor: { rgb: "FF9999" } }, font: { bold: true } }; // Light red fill for headers
            // XLSX.utils.sheet_add_aoa(wsStock, [stockedRow], { origin: -1 });
            // wsStock[`A${stockDevices.length + 2}`].s = { fill: { fgColor: { rgb: "FFCCCC" } }, font: { bold: true } }; // Light red fill and bold font for summary
            XLSX.utils.book_append_sheet(wb, wsStock, "Stock Devices");
          }
       
        }
        if(type == 'isTotal'){
          if(totalDevicesData.damageDevicesData.length > 0){
            const damagedRow = ['Total Damaged Devices Count', ` ${totalDevicesData.damageDevices}`];
            let damagedDevices = totalDevicesData.damageDevicesData.map((item, i) => {
              let installDate = moment(item.installDate).format('MMM D, YYYY')
              let subscriptionDate = moment(item.subscriptionDate).format('MMM D, YYYY')
              let simActvateDate = moment(item.simActvateDate).format('MMM D, YYYY')
              let expiryDate = moment(item.expiryDate).format('MMM D, YYYY')
              return {
                Sno: `${i + 1}`,
                "Client Name": item.companyName,
                "Service Provider" : item.deviceProviderName,
                "Registration Number": item.regiNum ? item.regiNum : '--',
                "Device IMEI Num": item.deviceImei,
                "Device Serial Num" : item.deviceSerialNum,
                "Device Install Date": installDate,
                "Device Install Month": moment(installDate,"MMM D, YYYY").format('MMMM'),
                "Device Status": item.deviceStatus == '1' ? 'Active' : 'In Active',
                "Subscription Type" : item.subscriptionType,
                "Subscription Date": subscriptionDate,
                "Subscription Expiry Date": expiryDate,
                "Sim Number": item.simNum,
                "Sim Activate Date": simActvateDate,
              };
            });
            const wsDamage = XLSX.utils.json_to_sheet(damagedDevices, { header: headers });
            XLSX.utils.sheet_add_aoa(wsDamage, [headers], { origin: 'A1' });
            wsDamage['!cols'] = headers.map(() => ({ width: 20 }));
            wsDamage['A1'].s = { fill: { fgColor: { rgb: "FF9999" } }, font: { bold: true } }; // Light red fill for headers
            // XLSX.utils.sheet_add_aoa(wsDamage, [damagedRow], { origin: -1 });
            // wsDamage[`A${damagedDevices.length + 2}`].s = { fill: { fgColor: { rgb: "FFCCCC" } }, font: { bold: true } }; // Light red fill and bold font for summary
            XLSX.utils.book_append_sheet(wb, wsDamage, "Damaged Devices");
          }
          if(totalDevicesData.lostDevicesData.length > 0){
            const lostedRow = ['Total Lost Devices Count', ` ${totalDevicesData.lostDevices}`];
            let lostDevices = totalDevicesData.lostDevicesData.map((item, i) => {
              let installDate = moment(item.installDate).format('MMM D, YYYY')
              let subscriptionDate = moment(item.subscriptionDate).format('MMM D, YYYY')
              let simActvateDate = moment(item.simActvateDate).format('MMM D, YYYY')
              let expiryDate = moment(item.expiryDate).format('MMM D, YYYY')
              return {
                Sno: `${i + 1}`,
                "Client Name": item.companyName,
                "Service Provider" : item.deviceProviderName,
                "Registration Number": item.regiNum ? item.regiNum : '--',
                "Device IMEI Num": item.deviceImei,
                "Device Serial Num" : item.deviceSerialNum,
                "Device Install Date": installDate,
                "Device Install Month": moment(installDate,"MMM D, YYYY").format('MMMM'),
                "Device Status": item.deviceStatus == '1' ? 'Active' : 'In Active',
                "Subscription Type" : item.subscriptionType,
                "Subscription Date": subscriptionDate,
                "Subscription Expiry Date": expiryDate,
                "Sim Number": item.simNum,
                "Sim Activate Date": simActvateDate,
              };
            });
            const wsLost = XLSX.utils.json_to_sheet(lostDevices, { header: headers });
            XLSX.utils.sheet_add_aoa(wsLost, [headers], { origin: 'A1' });
            wsLost['!cols'] = headers.map(() => ({ width: 20 }));
            wsLost['A1'].s = { fill: { fgColor: { rgb: "FF9999" } }, font: { bold: true } }; // Light red fill for headers
            // XLSX.utils.sheet_add_aoa(wsLost, [lostedRow], { origin: -1 });
            // wsLost[`A${lostDevices.length + 2}`].s = { fill: { fgColor: { rgb: "FFCCCC" } }, font: { bold: true } }; // Light red fill and bold font for summary
            XLSX.utils.book_append_sheet(wb, wsLost, "Lost Devices");
          }
          if(totalDevicesData.removedDevicesData.length > 0){
            const removedRow = ['Total Removed Devices Count', ` ${totalDevicesData.removeDevices}`];
            let removedDevices = totalDevicesData.removedDevicesData.map((item, i) => {
              let installDate = moment(item.installDate).format('MMM D, YYYY')
              let subscriptionDate = moment(item.subscriptionDate).format('MMM D, YYYY')
              let simActvateDate = moment(item.simActvateDate).format('MMM D, YYYY')
              let expiryDate = moment(item.expiryDate).format('MMM D, YYYY')
              return {
                Sno: `${i + 1}`,
                "Client Name": item.companyName,
                "Service Provider" : item.deviceProviderName,
                "Registration Number": item.regiNum ? item.regiNum : '--',
                "Device IMEI Num": item.deviceImei,
                "Device Serial Num" : item.deviceSerialNum,
                "Device Install Date": installDate,
                "Device Install Month": moment(installDate,"MMM D, YYYY").format('MMMM'),
                "Device Status": item.deviceStatus == '1' ? 'Active' : 'In Active',
                "Subscription Type" : item.subscriptionType,
                "Subscription Date": subscriptionDate,
                "Subscription Expiry Date": expiryDate,
                "Sim Number": item.simNum,
                "Sim Activate Date": simActvateDate,
              };
            });
            const wsRemoved = XLSX.utils.json_to_sheet(removedDevices, { header: headers });
            XLSX.utils.sheet_add_aoa(wsRemoved, [headers], { origin: 'A1' });
            wsRemoved['!cols'] = headers.map(() => ({ width: 20 }));
            wsRemoved['A1'].s = { fill: { fgColor: { rgb: "FF9999" } }, font: { bold: true } }; // Light red fill for headers
            // XLSX.utils.sheet_add_aoa(wsRemoved, [removedRow], { origin: -1 });
            // wsRemoved[`A${removedDevices.length + 2}`].s = { fill: { fgColor: { rgb: "FFCCCC" } }, font: { bold: true } }; // Light red fill and bold font for summary
            XLSX.utils.book_append_sheet(wb, wsRemoved, "Removed Devices");
          }
        }
       
        // Create file and download
        XLSX.writeFile(wb, "devices.xlsx");

          // setCsvData(csvArray);
          // setTimeout(() => {
          //   csvlink.current.link.click();
          // }, 500);
        }else {
          setDevicesData(res);
          setSpinner(false);
          setAlertData({
            open: true,
            severity: CONFIG.ALERT_SEVERITY.error,
            message: res.message,
          });
        }
      })
      .catch((error) => {
        setSpinner(false);
        setAlertData({
          open: true,
          severity: CONFIG.ALERT_SEVERITY.error,
          message:
            error.response && error.response.data
              ? error.response.data.message
              : "Something went wrong!",
        });
      });
     
       
      // })
      // .catch((error) => {
      //   setAlertData({
      //     open: true,
      //     severity: CONFIG.ALERT_SEVERITY.error,
      //     message:
      //       error.response && error.response.data
      //         ? error.response.data.message
      //         : "Something went wrong!",
      //   });
      // });
  };

  const vehicleSelectHandler = (value, name) => {
    let newFilters = _.cloneDeep(filters);
    newFilters[name]["value"] = value.join(","); 
    let data = [];
    let filterOptions = filters?.vehicleslist?.options?.filter((i) =>
      value.map((j) => {
        if (j === i.id) {
          return data.push(i);
        }
      })
    );
    newFilters[name]["actualValue"] = value;
    newFilters[name]["data"] = data;
    renderMyDevicesData(
      {
        skip: pagination.current * pagination.pageSize,
        limit: pagination.pageSize,
        companyId: newFilters?.companyName?.value?.value,
        deviceId: newFilters?.deviceImei?.value?.value,
        status: newFilters?.deviceStatus?.value?.value,
        truckIds: newFilters?.vehicleslist?.value,
        type: type,
      },
      columns
    );
    // setLoading(true);
    // const promise1 = global.service.getFuelReport({
    //   from: Math.floor(new Date(fromDateToHist).getTime() / 1000),
    //   to: Math.floor(new Date(toDateToHist).getTime() / 1000),
    //   vehicleList : value.join(",")
    // });
    // Promise.all([promise1])
    //   .then((response) => {
    //     if (response[0].data) {
    //       setFuelReports(response[0]);
    //       setErrorMessage(null)
    //     } else {
    //       setErrorMessage("No Data Available");
    //     }
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     setErrorMessage("Unable to fetch data from API");
    //   });
    setFilters(newFilters);
  };
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };
  return (
    <>
      <div>
        <Header />
        <div className="main_container">
          <div className="header_box">
            <Typography className="header_text">My Devices</Typography>
            <div className={classes.header_buttons}>
              
              <Button
              className={classes.export_button}
              startIcon={<DownloadIcon />}
              onClick={() => {
                getDownloadData();
              }}
            >
              Export
            </Button>
            <Button
                className={classes.client_button}
                startIcon={
                  <AddCircleOutlinedIcon style={{ fontSize: "18px" }} />
                }
                onClick={() => {
                  history.push("./addDevice");
                }}
                // onClick={() => {}}
              >
                Add Device
              </Button>
            {/* <CSVLink
              filename={"devices.csv"}
              data={csvData}
              style={{ display: "none" }}
              ref={csvlink}
            /> */}
            </div>
          </div>
          <div style={{ padding: "20px 24px 10px 24px", display: "flex" }}>
            <Card
              style={{
                padding: "5px",
                width: "20%",
                marginRight: "15px",
                cursor: "pointer",
                //   border: isComplitedClicked ? "2px solid #1890ff" : "none",
                // padding: '10px',
                //   cursor: "pointer",
                border: type === "isWorking" ? "2px solid #1890ff" : "",
              }}
              // onClick={() => statHandler("COMPLETED")}
              onClick={() => handleClickForCards("isWorking")}
            >
              <CardContent>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "75%" }}>
                    <Typography
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      {devicesData?.usedDevicesCount
                        ? devicesData.usedDevicesCount
                        : 0}
                    </Typography>
                    <Typography
                      style={{
                        color: "#366E93",
                        fontSize: "12px",
                        fontWeight: ["550"],
                        //   fontFamily: "Montserrat,SemiBold",
                      }}
                    >
                      Working Devices
                    </Typography>
                  </div>
                  <div
                    style={{
                      alignSelf: "center",
                    }}
                  >
                    <Avatar
                      style={{ background: "#3D739619", padding: "24px" }}
                    >
                      <img
                        src={workingDevices}
                        style={{
                          width: "50px",
                          height: "45px",
                        }}
                      />
                    </Avatar>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card
              style={{
                padding: "5px",
                width: "20%",
                marginRight: "15px",
                cursor: "pointer",
                //   border: isComplitedClicked ? "2px solid #1890ff" : "none",
                // padding: '10px',
                //   cursor: "pointer",
                border: type === "isTotal" ? "2px solid #1890ff" : "",
              }}
              // onClick={() => statHandler("COMPLETED")}
              onClick={() => handleClickForCards("isTotal")}
            >
              <CardContent>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "75%" }}>
                    <Typography
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      {devicesData?.totalCount ? devicesData.totalCount : 0}
                    </Typography>
                    <Typography
                      style={{
                        color: "#366E93",
                        fontSize: "12px",
                        fontWeight: ["550"],
                        //   fontFamily: "Montserrat,SemiBold",
                      }}
                    >
                      Total Devices
                    </Typography>
                  </div>
                  <div
                    style={{
                      alignSelf: "center",
                    }}
                  >
                    <Avatar
                      style={{ background: "#3D739619", padding: "24px" }}
                    >
                      <img
                        src={totalDevices}
                        style={{
                          width: "45px",
                          height: "45px",
                        }}
                      />
                    </Avatar>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card
              style={{
                padding: "5px",
                cursor: "pointer",
                width: "20%",
                marginRight: "15px",
                border: type === "isInstall" ? "2px solid #1890ff" : "",
                // border: isInstall ? "2px solid #1890ff" : "none",
                // padding: '10px',
                cursor: "pointer",
              }}
              // onClick={() => statHandler("ONTIME")}
              onClick={() => handleClickForCards("isInstall")}
            >
              <CardContent>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "75%" }}>
                    <Typography
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      {devicesData?.installedDevices
                        ? devicesData.installedDevices
                        : 0}
                    </Typography>
                    <Typography
                      style={{
                        color: "#366E93",
                        fontSize: "12px",
                        fontWeight: ["550"],
                        //   fontFamily: "Montserrat,SemiBold",
                      }}
                    >
                      Installed Devices
                    </Typography>
                  </div>
                  <div
                    style={{
                      alignSelf: "center",
                    }}
                  >
                    <Avatar
                      style={{ background: "#3D739619", padding: "24px" }}
                    >
                      <img
                        src={installedDevices}
                        style={{
                          width: "40px",
                          height: "40px",
                        }}
                      />
                    </Avatar>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card
              style={{
                padding: "5px",
                cursor: "pointer",
                width: "20%",
                marginRight: "15px",
                border: type === "isStock" ? "2px solid #1890ff" : "",
                //   border: toBeStarted ? "2px solid #1890ff" : "none",
                // padding: '10px',
                cursor: "pointer",
              }}
              // onClick={() => statHandler("DELAYED")}
              onClick={() => handleClickForCards("isStock")}
            >
              <CardContent>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "75%" }}>
                    <Typography
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      {devicesData?.stockDevices ? devicesData.stockDevices : 0}
                    </Typography>
                    <Typography
                      style={{
                        color: "#366E93",
                        fontSize: "12px",
                        fontWeight: ["550"],
                        //   fontFamily: "Montserrat,SemiBold",
                      }}
                    >
                      Stock Devices
                    </Typography>
                  </div>
                  <div
                    style={{
                      fontWeight: "bold",
                      alignSelf: "center",
                    }}
                  >
                    <Avatar
                      style={{ background: "#3D739619", padding: "24px" }}
                    >
                      <img
                        src={stockDevices}
                        style={{
                          width: "45px",
                          height: "45px",
                        }}
                      />
                    </Avatar>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card
              style={{
                padding: "5px",
                cursor: "pointer",
                width: "20%",
                marginRight: "15px",
                border: type === "isDamage" ? "2px solid #1890ff" : "",
                //   border: toBeStarted ? "2px solid #1890ff" : "none",
                // padding: '10px',
                cursor: "pointer",
              }}
              // onClick={() => statHandler("DELAYED")}
              onClick={() => handleClickForCards("isDamage")}
            >
              <CardContent>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "75%" }}>
                    <Typography
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      {devicesData?.damageDevices
                        ? devicesData.damageDevices
                        : 0}
                    </Typography>
                    <Typography
                      style={{
                        color: "#366E93",
                        fontSize: "12px",
                        fontWeight: ["550"],
                        //   fontFamily: "Montserrat,SemiBold",
                      }}
                    >
                      Damaged Devices
                    </Typography>
                  </div>
                  <div
                    style={{
                      fontWeight: "bold",
                      alignSelf: "center",
                    }}
                  >
                    <Avatar
                      style={{ background: "#3D739619", padding: "24px" }}
                    >
                      <img
                        src={damageDevices}
                        style={{
                          width: "45px",
                          height: "45px",
                        }}
                      />
                    </Avatar>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card
              style={{
                padding: "5px",
                cursor: "pointer",
                width: "20%",
                marginRight: "15px",
                border: type === "isLost" ? "2px solid #1890ff" : "",
                //   border: toBeStarted ? "2px solid #1890ff" : "none",
                // padding: '10px',
                cursor: "pointer",
              }}
              // onClick={() => statHandler("DELAYED")}
              onClick={() => handleClickForCards("isLost")}
            >
              <CardContent>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "75%" }}>
                    <Typography
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      {devicesData?.lostDevices ? devicesData.lostDevices : 0}
                    </Typography>
                    <Typography
                      style={{
                        color: "#366E93",
                        fontSize: "12px",
                        fontWeight: ["550"],
                        //   fontFamily: "Montserrat,SemiBold",
                      }}
                    >
                      Lost Devices
                    </Typography>
                  </div>
                  <div
                    style={{
                      fontWeight: "bold",
                      alignSelf: "center",
                    }}
                  >
                    <Avatar
                      style={{ background: "#3D739619", padding: "24px" }}
                    >
                      <img
                        src={lostDevices}
                        style={{
                          width: "50px",
                          height: "45px",
                        }}
                      />
                    </Avatar>
                  </div>
                </div>
              </CardContent>
            </Card>
            <Card
              style={{
                padding: "5px",
                cursor: "pointer",
                width: "20%",
                marginRight: "15px",
                border: type === "isRemoved" ? "2px solid #1890ff" : "",
                //   border: toBeStarted ? "2px solid #1890ff" : "none",
                // padding: '10px',
                cursor: "pointer",
              }}
              // onClick={() => statHandler("DELAYED")}
              onClick={() => handleClickForCards("isRemoved")}
            >
              <CardContent>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ width: "75%" }}>
                    <Typography
                      style={{ fontWeight: "bold", fontSize: "20px" }}
                    >
                      {devicesData?.removeDevices
                        ? devicesData.removeDevices
                        : 0}
                    </Typography>
                    <Typography
                      style={{
                        color: "#366E93",
                        fontSize: "12px",
                        fontWeight: ["550"],
                        //   fontFamily: "Montserrat,SemiBold",
                      }}
                    >
                      Removed Devices
                    </Typography>
                  </div>
                  <div
                    style={{
                      fontWeight: "bold",
                      alignSelf: "center",
                    }}
                  >
                    <Avatar
                      style={{ background: "#3D739619", padding: "24px" }}
                    >
                      <img
                        src={removeDevices}
                        style={{
                          width: "60px",
                          height: "55px",
                        }}
                      />
                    </Avatar>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="filter_box">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Fields.AntSelectableSearchField
                      fieldData={filters.companyName}
                      autoCompleteChangeHandler={autoCompleteChangeHandler}
                    />
                  </Grid>
                  <Grid item xs={2}>
                  <MultiselectFields
                  fieldData={filters.vehicleslist}
                  options={optionsList}
                  selectChangeHandler={vehicleSelectHandler}
                  mode={"multiple"}
                />
                  </Grid>
                  <Grid item xs={2}>
                    <Fields.AntSelectableSearchField
                      fieldData={filters.deviceImei}
                      autoCompleteChangeHandler={autoCompleteChangeHandler}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <Fields.AntSelectableSearchField
                      fieldData={filters.deviceStatus}
                      autoCompleteChangeHandler={autoCompleteChangeHandler}
                    />
                  </Grid>
                  {/* <Grid item xs={3}>
                    <Fields.DateRangePickerField
                      fieldData={filters.dateRange}
                      dateRangeChangeHandler={dateRangeChangeHandler}
                    />
                  </Grid> */}
                  <Grid
                    item
                    xs={2}
                    style={{ 
                      // alignSelf: "flex-end", 
                      // marginBottom: "4px" 
                      }}
                  >
                    <Button
                      className={classes.clear_button}
                      startIcon={<CloseIcon />}
                      onClick={() => {
                        clearHandler();
                      }}
                    >
                      Clear
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div>
            {alertData.open ? (
              <div className={classes.alertBox}>
                <AlertMessage
                  severity={alertData.severity}
                  message={alertData.message}
                  closeAlert={closeAlert}
                />
              </div>
            ) : null}
            {spinner ? (
              <CircularLoading />
            ) : (
              <Components.DataTable
                headRows={headRows}
                cols={cols}
                tableData={tableData}
                pagination={pagination}
                pageChangeHandler={pageChangeHandler}
                rowsPerPageChangeHandler={rowsPerPageChangeHandler}
                total={total}
                columnCheckHandler={columnCheckHandler}
              />
            )}
          </div>
          {/* <AssignDeviceToTruck open = {assign}/> */}
          {assign && (
            <AssignDeviceToTruck
              open={assign}
              onClose={() => setAssign(false)}
              assignData={assignData}
            />
          )}

          {transfer && (
            <TransferTruck
              open={transfer}
              onClose={() => setTransfer(false)}
              assignData={assignData}
            />
          )}
          {damage && (
            <DamageDevice
              open={damage}
              onClose={() => setDamage(false)}
              assignData={assignData}
            />
          )}
          {remove && (
            <RemoveDevice
              open={remove}
              onClose={() => setRemove(false)}
              assignData={assignData}
            />
          )}
          {move && (
            <MoveDeviceToSubaccount
              open={move}
              onClose={() => setMoved(false)}
              assignData={assignData}
            />
          )}
          {lost && (
            <LostDevice
              open={lost}
              onClose={() => setLost(false)}
              assignData={assignData}
            />
          )}
          {assignSim && (
            <AssignSimToDevice
              open={assignSim}
              onClose={() => setAssignSim(false)}
              assignData={assignData}
            />
          )}
          {subscription && (
            <SubscriptionOfDevice
              open={subscription}
              onClose={() => setSubscription(false)}
              assignData={assignData}
            />
          )}
        </div>
      </div>
    </>
  );
};
