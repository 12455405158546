import { Paper, makeStyles } from "@material-ui/core";
import AlertMessage from "../components/alertmessage/AlertMessage";
import * as Fields from "../sharedComponents";
import { Grid, CircularProgress } from "@material-ui/core";
import { Link, Typography, Button } from "@material-ui/core";
import * as CONFIG from "../config/GlobalConstants";
import { useHistory, useLocation } from "react-router-dom";
import { InputAdornment } from "@material-ui/core";
import VisibilityIcon from "@material-ui/icons/Visibility";

import {
  logoImage as Logo,
  backgroundImage as Background,
} from "../assets/index";
import { useEffect, useState } from "react";
import _ from "lodash";
import ValidateFields from "../validations/validateFields";

const useStyles = makeStyles((theme) => ({
  img_style: {
    width: "250px",
    height: "107px",
    display: "block",
    margin: "auto",
  },
  paper_style: {
    padding: 20,
    width: 280,
    margin: "20px auto",
    borderRadius: 10,
  },
  alertBox: {
    padding: "0px",
  },
  forgot_password: {
    fontSize: "10pt",
    color: "#366E93",
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "#366E93",
    color: "#FFFFFF",
    textTransform: "none",
  },
  registernow: {
    fontSize: "10pt",
    color: "#366E93",
    fontWeight: "bold",
  },
  account: {
    fontSize: "10pt",
    color: "#777777",
  },
  bottom_fields: {
    textAlign: "center",
    marginTop: "5%",
    padding: "10px",
  },
}));

export function ConfirmPassword() {
  const location = useLocation();
  const ownerId = localStorage.getItem("ownerId");
  let id = ownerId ? JSON.parse(ownerId) : null;
  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;
  const history = useHistory();
  const [spinner, setSpinner] = useState(false);
  let validateFields = new ValidateFields();
  const classes = useStyles();
  const formFields = {
    otpCode: {
      name: "otpCode",
      // label:"Number of Vehicles",
      topLabel: true,
      value: "",
      placeholder: "Enter Four Degits Code",
      errorMsg: "Please enter code",
      validPattern: "SPECIAL_CHARS_DESC",
      validationRequired: true,
      isValid: true,
      maxLength: 4,
      min: 0,
    },
    newPassword: {
      name: "newPassword",
      label: "New Password",
      topLabel: true,
      value: "",
      placeholder: "New Password",
      errorMsg: "Please enter new password",
      validPattern: "PASSWORD",
      validationRequired: true,
      isValid: true,
      type: "password",
    },
    confirmPassword: {
      name: "confirmPassword",
      label: "Confirm Password",
      topLabel: true,
      value: "",
      placeholder: "Confirm Password",
      errorMsg: "Please enter confirm password",
      validPattern: "PASSWORD",
      validationRequired: true,
      isValid: true,
      type: "password",
    },
  };
  let Service = global.service;
  const [formData, setFormData] = useState(formFields);
  const [timer, setTimer] = useState(30);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [timer]);
  const inputChangeHandler = (value, name) => {
    let newFields = _.cloneDeep(formData);
    newFields[name]["value"] = value;
    newFields[name]["isValid"] = true;
    if (value.length === 0 && newFields[name]["validationRequired"]) {
      newFields[name]["isValid"] = false;
    }
    setFormData(newFields);
  };
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };
  const forReSendingOtp = () => {
    Service.forgotResendCode({ id: ownerId }).then((response) => {
      if (response.status == true) {
        setAlertData({
          open: true,
          message: response.message,
          severity: "success",
        });
      } else {
        setAlertData({
          open: true,
          message: response.message,
          severity: "error",
        });
      }
    });
    setTimer(30);
  };
  const onClickHandler = async (formData, event) => {
    event.preventDefault();
    let dataStatus = await validateFields.validateFieldsData(formData);
    if (formData.newPassword.value !== formData.confirmPassword.value) {
      formData.confirmPassword.isValid = false;
      formData.confirmPassword.errorMsg = "Please enter correct password";
      setFormData(_.cloneDeep(formData));
    }

    if (dataStatus.status && formData.confirmPassword.isValid) {
      let newObject = {
        id: id,
        sms_vcode: formData.otpCode.value,
        password: formData.newPassword.value,
      };
      Service.resetNewPassword(newObject).then((response) => {
        if (response.status == true) {
          setAlertData({
            open: true,
            message: response.message,
            severity: "success",
          });
          localStorage.removeItem("ownerId");
          // global.session.remove("BearerToken");
          // global.session.remove("profile");
          // global.session.remove('hideHeader');
          window.location = "/";
        } else {
          setAlertData({
            open: true,
            message: response.message,
            severity: "error",
          });
        }
      });
    } else {
      let newFields = _.cloneDeep(formFields);
      let erroredFields = Object.keys(dataStatus.data).filter(
        (key, i) => dataStatus.data[key].isValid === false
      );
      newFields = dataStatus.data;
      setFormData(newFields);
    }
  };
  return (
    <div>
      <Paper
        style={{
          backgroundImage: `url(${Background})`,
          width: winWidth,
          height: winHeight,
          backgroundSize: "contain",
        }}
      >
        <div>
          <img src={Logo} className={classes.img_style} />
        </div>
        <div>
          <Paper elevation={10} className={classes.paper_style}>
            {alertData.open ? (
              <div className={classes.alertBox}>
                <AlertMessage
                  severity={alertData.severity}
                  message={alertData.message}
                  closeAlert={closeAlert}
                />
              </div>
            ) : null}
            <Typography
              style={{
                marginBottom: "10px",
                color: "#366E93",
                fontSize: "20px",
              }}
            >
              Set your new password
            </Typography>
            <Grid item xs={12}>
              <Fields.InputField
                fullWidth={true}
                fieldData={formData.otpCode}
                inputChangeHandler={inputChangeHandler}
                variant="outlined"
              />
            </Grid>
            {timer > 0 ? (
              <p style={{ fontWeight: "bold", marginTop: "5px" }}>
                Resend OTP in <span style={{ color: "red" }}>{timer}</span>{" "}
                seconds
              </p>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "5px",
                }}
              >
                <Button
                  size="small"
                  style={{ textTransform: "none", color: "#FF5733" }}
                  onClick={forReSendingOtp}
                >
                  Resend otp
                </Button>
              </div>
            )}

            <Grid item xs={12}>
              <Fields.InputField
                fullWidth={true}
                fieldData={formData.newPassword}
                inputChangeHandler={inputChangeHandler}
                variant="outlined"
                endAdornment={
                  <InputAdornment style={{ backgroundColor: "#777777" }}>
                    <VisibilityIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        let newFields = _.cloneDeep(formData);
                        if (newFields.newPassword.type === "password") {
                          newFields.newPassword.type = "text";
                          setFormData(newFields);
                        } else {
                          newFields.newPassword.type = "password";
                          setFormData(newFields);
                        }
                      }}
                    />
                  </InputAdornment>
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Fields.InputField
                fullWidth={true}
                fieldData={formData.confirmPassword}
                inputChangeHandler={inputChangeHandler}
                variant="outlined"
                endAdornment={
                  <InputAdornment style={{ backgroundColor: "#777777" }}>
                    <VisibilityIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        let newFields = _.cloneDeep(formData);
                        if (newFields.confirmPassword.type === "password") {
                          newFields.confirmPassword.type = "text";
                          setFormData(newFields);
                        } else {
                          newFields.confirmPassword.type = "password";
                          setFormData(newFields);
                        }
                      }}
                    />
                  </InputAdornment>
                }
              />
            </Grid>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth={true}
              className={classes.button}
              style={{ marginTop: "20px", marginBottom: "5px" }}
              onClick={(event) => onClickHandler(formData, event)}
            >
              {spinner ? (
                <CircularProgress size={24} color={"#FFFFFF"} />
              ) : (
                "Done"
              )}
            </Button>
          </Paper>
        </div>
      </Paper>
    </div>
  );
}

export function ForgotPassword() {
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  let Service = global.service;
  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;
  const history = useHistory();
  const [spinner, setSpinner] = useState(false);
  const classes = useStyles();
  let validateFields = new ValidateFields();
  let fields = {
    mobileNo: {
      name: "mobileNo",
      label: "Mobile Number",
      maxLength: 10,
      topLabel: true,
      validPattern: "NUMERIC",
      validationRequired: true,
      errorMsg: "Please Enter Mobile Number",
      isValid: true,
      placeholder: "Enter mobile number",
      value: "",
    },
  };
  let [formFields, setFormFields] = useState(fields);
  const [countryCode, setCountryCode] = useState({
    countryCode: "in",
    dialCode: "91",
  });
  const phoneChangeHandler = (country, value, name) => {
    let newFields = _.cloneDeep(formFields);
    let newCountryCode = _.cloneDeep(countryCode);
    newCountryCode = {
      countryCode: country.countryCode,
      dialCode: country.dialCode,
    };
    newFields[name]["value"] = value;
    newFields[name]["isValid"] = true;
    if (value.length > 2 && newFields[name]["validationRequired"]) {
      newFields[name]["isValid"] = true;
    }
    setCountryCode(newCountryCode);
    setFormFields(newFields);
  };
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };
  const handelingConfirmPassword = async (formFields, event) => {
    let dataStatus = await validateFields.validateFieldsData(formFields);
    if (dataStatus.status) {
      let newObject = {
        mobile: formFields.mobileNo.value.replace(countryCode.dialCode, ""),
      };
      Service.forgotPassword(newObject).then((response) => {
        if (response.status == true) {
          localStorage.setItem("ownerId", JSON.stringify(response.id));
          setAlertData({
            open: true,
            message: response.message,
            severity: "success",
          });
          window.location = "./confirm-password";
        } else {
          setAlertData({
            open: true,
            message: response.message,
            severity: "error",
          });
        }
      });
    } else {
      let newFields = _.cloneDeep(formFields);
      let erroredFields = Object.keys(dataStatus.data).filter(
        (key, i) => dataStatus.data[key].isValid === false
      );
      newFields = dataStatus.data;
      setFormFields(newFields);
    }
  };
  return (
    <div>
      <Paper
        style={{
          backgroundImage: `url(${Background})`,
          width: winWidth,
          height: winHeight,
          backgroundSize: "contain",
        }}
      >
        <div>
          <img src={Logo} className={classes.img_style} />
        </div>
        <div>
          <Paper elevation={10} className={classes.paper_style}>
            {alertData.open ? (
              <div className={classes.alertBox}>
                <AlertMessage
                  severity={alertData.severity}
                  message={alertData.message}
                  closeAlert={closeAlert}
                />
              </div>
            ) : null}
            <Typography
              style={{
                marginBottom: "10px",
                color: "#366E93",
                fontSize: "20px",
              }}
            >
              Please enter your mobile number to reset password
            </Typography>
            <Grid item xs={12}>
              <Fields.PhoneNumberField
                fieldData={formFields.mobileNo}
                variant="outlined"
                phoneChangeHandler={phoneChangeHandler}
                defaultCountry={countryCode}
              />
            </Grid>
            <div>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Button
                        type="submit"
                        color="primary"
                        variant="contained"
                        fullWidth
                        className={classes.button}
                        style={{ marginTop: "5px", marginBottom: "5px", textTransform: "none", marginTop: "20px", marginBottom: "5px",
                        backgroundColor: "#9d9d9d", // custom background color
                        color: "white" }}
                        onClick={() => {
                          history.push('/');
                          window.location.reload()
                          // window.location = '/'
                        }}
                      >
                        {spinner ? (
                          <CircularProgress size={24} style={{ color: "#FFFFFF" }} />
                        ) : (
                          "Cancel"
                        )}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth={true}
              className={classes.button}
              style={{ marginTop: "20px", marginBottom: "5px" }}
              onClick={(event) => handelingConfirmPassword(formFields, event)}
            >
              {spinner ? (
                <CircularProgress size={24} color={"#FFFFFF"} />
              ) : (
                "Next"
              )}
            </Button>
            </Grid>
            </Grid>
            </div>
          </Paper>
        </div>
      </Paper>
    </div>
  );
}
