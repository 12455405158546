import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useHistory } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import NestedMenuItem from "material-ui-nested-menu-item";
import { useDispatch } from "react-redux";

function AppMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const dispatch = useDispatch();
  const handleItemsClick = (event) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: 15,
      left: 207,
    });
  };
  const handleItemClick = (event) => {
    setMenuPosition(null);
  };
  let history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const navigateTorandom = (path) => {
    history.push(path);
    dispatch({
      type: "SEARCH_FLEET_VENDOR_PAYMENTS_DETAILS",
      payload: {
        name: "",
        companyName: "",
      },
    });
  };

  const data = sessionStorage.getItem("profile");
  const profile = JSON.parse(data);
  const getMenuItems = () => {
    if (profile.role_id === 5) {
      return (
        <MenuItem onClick={() => history.push("./vehiclerequestlist")}>
          Vehicle Request List
        </MenuItem>
      );
    } else if (profile.role_id === 15) {
      return (
        <NestedMenuItem
          style={{ width: "15vw" }}
          label="Vehicle Request"
          parentMenuOpen={!!menuPosition}
          onClick={handleItemClick}
        >
          <MenuItem onClick={() => navigateTorandom("./requestList")}>
            Request List
          </MenuItem>
          <MenuItem onClick={() => navigateTorandom("./addFleetVender")}>
            Add
          </MenuItem>
          <MenuItem onClick={() => navigateTorandom("./exchange")}>
            Exchange
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./transfer")}
          >
            Transfer
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./remove")}
          >
            Remove
          </MenuItem>
        </NestedMenuItem>
      );
    } else if (profile.role_id === 0) {
      return (
        <MenuItem
          onClick={() => navigateTorandom("./vehiclerequestverificationlist")}
        >
          Vehicle Request List
        </MenuItem>
      );
    }
  };

  return (
    <div onContextMenu={handleClick}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        variant="contained"
        onClick={handleItemsClick}
        style={{ textTransform: "none" }}
        startIcon={<MenuIcon />}
      >
        Menu
      </Button>
      <Menu
        open={!!menuPosition}
        onClose={() => setMenuPosition(null)}
        anchorReference="anchorPosition"
        anchorPosition={menuPosition}
      >
        <NestedMenuItem
          style={{ width: "15vw" }}
          label="My Fleet"
          parentMenuOpen={!!menuPosition}
          onClick={handleItemClick}
        >
          {profile.business_type === `HealthCare` ? (
            <MenuItem onClick={() => navigateTorandom("./myFleetVehicles")}>
              Live
            </MenuItem>
          ) : (
            <MenuItem onClick={() => navigateTorandom("./myFleet")}>
              Live
            </MenuItem>
          )}
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./dashboard")}
          >
            Trackit
            {/* Fuel */}
          </MenuItem>
          {/* <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./MBRDashboard")}
          >
            Dashboard
          </MenuItem> */}
          {getMenuItems()}
        </NestedMenuItem>

        <NestedMenuItem
          style={{ width: "15vw" }}
          label="Finance"
          parentMenuOpen={!!menuPosition}
          onClick={handleItemClick}
        >
          {/* <MenuItem onClick={() => history.push("./myFleet")}>
            Billing&Payments
          </MenuItem> */}

          <NestedMenuItem
            style={{ width: "20vw" }}
            label="Billing & Payments"
            parentMenuOpen={!!menuPosition}
            onClick={handleItemClick}
          >
            <MenuItem onClick={() => navigateTorandom("./banks")}>
              Banks
            </MenuItem>
            <MenuItem
              onClick={() => navigateTorandom("./journal-transactions")}
            >
              Journal Transactions
            </MenuItem>
            <MenuItem onClick={() => navigateTorandom("./billingAndPayments")}>
              Client Invoices
            </MenuItem>
            <MenuItem
              style={{ width: "18vw" }}
              onClick={() => navigateTorandom("./serviceProviderPayments")}
            >
              Service Vendor Payments
            </MenuItem>
            <MenuItem
              style={{ width: "15vw" }}
              onClick={() => navigateTorandom("./pilotPayments")}
            >
              Pilot Payments
            </MenuItem>
            <MenuItem
              style={{ width: "15vw" }}
              onClick={() => navigateTorandom("./pettyCash")}
            >
              Petty Cash
            </MenuItem>
            <MenuItem
              style={{ width: "15vw" }}
              onClick={() => navigateTorandom("./fleetVendorPayments")}
            >
              Fleet Vendor Payments
            </MenuItem>
          </NestedMenuItem>

          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./Indent")}
          >
            Indent
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./reports")}
          >
            Ledger & Reports
          </MenuItem>
        </NestedMenuItem>

        {/* <MenuItem onClick={() => history.push("./dashboard")}>
          Dashboard
        </MenuItem> */}

        {/* <NestedMenuItem
          style={{ width: "15vw" }}
          label="Accounts"
          parentMenuOpen={!!menuPosition}
          onClick={handleItemClick}
        >
          <MenuItem onClick={() => history.push("./TripSheet")}>
            Trip SheetList
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => history.push("./CreateTripSheet")}
          >
            Create TripSheet
          </MenuItem>
        </NestedMenuItem> */}
        {/* <MenuItem onClick={() => history.push("./myFleet")}>My Fleet</MenuItem> */}
        {/* <MenuItem onClick={() => history.push("./clients")}>Clients</MenuItem> */}
        {/* <MenuItem onClick={() => history.push("./allRoutes")}>Routes</MenuItem> */}
        {/* <MenuItem onClick={() => history.push("/Trip")}>Trips</MenuItem> */}
        {/* <MenuItem onClick={() => history.push("./Indent")}>Indents</MenuItem> */}
        {/* <MenuItem onClick={() => history.push('./Vendor')}>Vendor</MenuItem> */}
        {/* <MenuItem onClick={() => history.push('./drivers')}>Pilots</MenuItem> */}
        {/* <MenuItem onClick={() => history.push('./Users')}>myUsers</MenuItem> */}

        {/* <NestedMenuItem
          style={{ width: "15vw" }}
          label="Billing&Payments"
          parentMenuOpen={!!menuPosition}
          onClick={handleItemClick}
        >
          <MenuItem onClick={() => history.push("./banks")}>
            Banks
          </MenuItem>
          <MenuItem onClick={() => history.push("./journal-transactions")}>
            Journal Transactions
          </MenuItem>
          <MenuItem onClick={() => history.push("./billingAndPayments")}>
            Client Invoices
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => history.push("./serviceProviderPayments")}
          >
            Service Vendor Payments
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => history.push("./pilotPayments")}
          >
            Pilot Payments
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => history.push("./pettyCash")}
          >
            Petty Cash
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => history.push("./fleetVendorPayments")}
          >
            Fleet Vendor Payments
          </MenuItem>
        </NestedMenuItem> */}
        {/* {getMenuItems()} */}
        {/* <NestedMenuItem
          style={{ width: "15vw" }}
          label="Vehicle Request"
          parentMenuOpen={!!menuPosition}
          onClick={handleItemClick}
        >
          <MenuItem onClick={() => history.push("./requestList")}>
            Request List
          </MenuItem>
          <MenuItem onClick={() => history.push("./addFleetVender")}>
            Add
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => history.push("./transfer")}
          >
            Transfer
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => history.push("./remove")}
          >
            Remove
          </MenuItem>
        </NestedMenuItem> */}

        {/* <MenuItem onClick={() => history.push('./reports')}>Ledger Reports</MenuItem> */}

        <NestedMenuItem
          style={{ width: "15vw" }}
          label="Operations"
          parentMenuOpen={!!menuPosition}
          onClick={handleItemClick}
        >
          <MenuItem onClick={() => navigateTorandom("./Trip")}>Trips</MenuItem>
          <MenuItem onClick={() => navigateTorandom("./myvehicle")}>
            My Vehicles
          </MenuItem>
          {profile.comp_id == 43 && (
            <MenuItem onClick={() => navigateTorandom("./myDevices")}>
              My Devices
            </MenuItem>
          )}

          {/* <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./drivers")}
          >
            Pilots
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./clients")}
          >
            Clients
          </MenuItem> */}
          {/* <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./AllContracstsLists")}
          >
            Client-Contracts
          </MenuItem> */}
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./allDeliveryOrdersList")}
          >
            Delivery Orders
          </MenuItem>
          {/* <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./PilotsList")}
          >
            Pilots V2
          </MenuItem> */}
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./allRoutes")}
          >
            Routes
          </MenuItem>
          {/* <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./shiftmanagement")}
          >
            Shift Management
          </MenuItem> */}
          <NestedMenuItem
            style={{ width: "15vw" }}
            label="Shift Planner"
            parentMenuOpen={!!menuPosition}
            onClick={handleItemClick}
          >
            <MenuItem
              style={{ width: "15vw" }}
              onClick={() => navigateTorandom("./shiftscheduler")}
            >
              Shift Scheduler
            </MenuItem>
            <MenuItem onClick={() => navigateTorandom("./shiftmanagement")}>
              Shift Management
            </MenuItem>
          </NestedMenuItem>
          {/* <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./myvehicle")}
          >
            My Vehicles
          </MenuItem> */}

          {/* <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./Nav")}
          >
            Vehicles
          </MenuItem> */}
        </NestedMenuItem>

        {profile.comp_id == 43 && (
          <NestedMenuItem
            style={{ width: "15vw" }}
            label="Store Inventory"
            parentMenuOpen={!!menuPosition}
            onClick={handleItemClick}
          >
            <MenuItem
              style={{ width: "15vw" }}
              onClick={() => navigateTorandom("./storeInventory")}
            >
              List
            </MenuItem>
            {/* <MenuItem style={{ width: "15vw" }} onClick={() => navigateTorandom("./storeInventory")}>List</MenuItem>
          <MenuItem style={{ width: "15vw" }} onClick={() => navigateTorandom("./storeInventory")}>List</MenuItem> */}
          </NestedMenuItem>
        )}

        <NestedMenuItem
          style={{ width: "15vw" }}
          label="Admin"
          parentMenuOpen={!!menuPosition}
          onClick={handleItemClick}
        >
          <NestedMenuItem
            style={{ width: "15vw" }}
            label="Users"
            parentMenuOpen={!!menuPosition}
            onClick={handleItemClick}
          >
            <MenuItem onClick={() => navigateTorandom("./CompanyUsers")}>
              Company Users
            </MenuItem>
            {/* <MenuItem
              style={{ width: "15vw" }}
              onClick={() => navigateTorandom("./FleetUsers")}
            >
              Fleet Users
            </MenuItem> */}
            <MenuItem
              style={{ width: "15vw" }}
              onClick={() => navigateTorandom("./drivers")}
            >
              Pilots
            </MenuItem>
          </NestedMenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./clients")}
          >
            Clients
          </MenuItem>
          <NestedMenuItem
            style={{ width: "15vw" }}
            label="Vendors"
            parentMenuOpen={!!menuPosition}
            onClick={handleItemClick}
          >
            <MenuItem onClick={() => navigateTorandom("./FleetVendor")}>
              Fleet Vendors
            </MenuItem>
            <MenuItem
              style={{ width: "15vw" }}
              onClick={() => navigateTorandom("./ServiceVendor")}
            >
              Service Vendors
            </MenuItem>
          </NestedMenuItem>
          <MenuItem onClick={() => navigateTorandom("./sites")}>Sites</MenuItem>
          <MenuItem onClick={() => navigateTorandom("./Groups")}>
            Groups
          </MenuItem>
          {/* <MenuItem onClick={() => navigateTorandom("./sites")}>Sites</MenuItem> */}
          {/* <NestedMenuItem
            style={{ width: "15vw" }}
            label="Users"
            parentMenuOpen={!!menuPosition}
            onClick={handleItemClick}
          >
            <MenuItem onClick={() => navigateTorandom("./CompanyUsers")}>
              Company Users
            </MenuItem>
           <MenuItem
              style={{ width: "15vw" }}
              onClick={() => navigateTorandom("./FleetUsers")}
            >
              Fleet Users
            </MenuItem> 
            <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./drivers")}
          >
            Pilots
          </MenuItem>
          </NestedMenuItem> */}
        </NestedMenuItem>
        <NestedMenuItem
          style={{ width: "15vw" }}
          label="Reports"
          parentMenuOpen={!!menuPosition}
          onClick={handleItemClick}
        >
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./fuelreport")}
          >
            Fuel Refill Report
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./FuelConsumptionReport")}
          >
            Fuel Consumption Report
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./DistanceReport")}
          >
            Distance Report
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./DistanceSummaryReport")}
          >
            Distance Summary Report
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./SpeedReport")}
          >
            Speed Report
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./SpeedwiseReport")}
          >
            Speed wise Report
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./IgnitionReport")}
          >
            Ignition Report
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./Breakdownreport")}
          >
            Breakdown Report
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./HourlyPingReport")}
          >
            Hourly Ping Report
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./TrackHistoryReport")}
          >
            Vehicle Real Time Report
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./ProfitAndLossReport")}
          >
            Profit and Loss Report
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./Emptyvehiclewaitingreport")}
          >
            Empty Vehicle Waiting Report
          </MenuItem>
          <MenuItem
            style={{ width: "15vw" }}
            onClick={() => navigateTorandom("./TripsReport")}
          >
            Auto Trip Report
          </MenuItem>
        </NestedMenuItem>
      </Menu>
    </div>
  );
}
export default AppMenu;
