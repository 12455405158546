import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  Popup,
  LayersControl,
  Circle,
  Polygon
} from "react-leaflet";
import "./custom.css";
import "leaflet/dist/leaflet.css";
import LocationMarker from "./marker";
import * as L from "leaflet";
import { format } from "date-fns";

// import { redMarkerImage } from "../../../assets/index";
// import { skyblueMarkerImage } from "../../../assets/index";
// import { yellowMarkerImage } from "../../../assets/index";
import { blueMarkerImage, orangeMarkerImage } from "../../../assets/index";

import { scheduledStopSvg } from "../../../assets/index";
// import { scheduledStopImage2 } from "../../../assets/index";
// import { unscheduledStopImage2 } from "../../../assets/index";
import {
  unscheduledStopSvg,
} from "../../../assets/index";

import { speedIcon } from "../../../assets/index";
import { accelerationIcon } from "../../../assets/index";
import { cornerIcon } from "../../../assets/index";
import { brakeIcon } from "../../../assets/index";
import {excavator} from "../../../assets/index";
import {JCBicon2} from "../../../assets/index";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    margin: "auto",
    backgroundColor: "#000",
    alignItems: "center",
    height: "100%",
  },
  leafletContainer: {
    margin: "auto",
    width: "100%",
    height: (props) => props.height,
  },
  leafletMarkerIcon: {
    width: "20px !important",
    height: "35px !important",
  },
  icon: {
    width: 40,
    height: 40,
    margin: 5,
    color: "#FF0000",
  },
}));

export default function Map(props) {
  // const position = [51.505, -0.09];
  const classes = useStyles(props);
  const [showOverSpeedMarkers, setShowOverSpeedMarkers] = useState(true);
  const [showCrashMarkers, setShowCrashMarkers] = useState(false);
  const [showHarshAccelerationMarkers, setShowHarshAccelerationMarkers] =
    useState(false);
  const [showHarshBrakingMarkers, setShowHarshBrakingMarkers] = useState(false);
  const [showHarshCorneringMarkers, setShowHarshCorneringMarkers] =
    useState(false);
  const [showScheduledMarkers, setShowScheduledMarkers] = useState(false);
  const [showUnScheduledMarkers, setShowUnScheduledMarkers] = useState(false);

  const [overSpeedingData, setOverSpeedingData] = useState([]);
  const [crashData, setCrashData] = useState([]);
  const [harshAccelerationData, setHarshAccelerationData] = useState([]);
  const [harshBrakingData, setHarshBrakingData] = useState([]);
  const [harshCorneringData, setHarshCorneringData] = useState([]);
  const [scheduledData, setScheduledData] = useState([]);
  const [unScheduledData, setUnScheduledData] = useState([]);

  const getDriverBehaviorData = (trackingInput) => {
    let vehicleFlespiId = null;
    if (props.SelectedVehicle && props.SelectedVehicle.flespi_id) {
      vehicleFlespiId = props.SelectedVehicle.flespi_id;
    }
    setOverSpeedingData([]);
    setCrashData([]);
    setHarshAccelerationData([]);
    setHarshBrakingData([]);
    setHarshCorneringData([]);
    setScheduledData([]);
    setUnScheduledData([]);

    if (vehicleFlespiId) {
      let input = {
        flespiId: vehicleFlespiId,
        fromUnixTimestamp: parseInt(new Date(props.fromDate).getTime() / 1000),
        toUnixTimestamp: parseInt(new Date(props.toDate).getTime() / 1000),
      };
      let scheduledInput = {
        flespiId: vehicleFlespiId,
        fromTimestamp: parseInt(new Date(props.fromDate).getTime() / 1000),
        toTimestamp: parseInt(new Date(props.toDate).getTime() / 1000),
      };
      // scheduledInput = {
      //   "flespiId": 2741237,
      //   "fromTimestamp": 1644949800,
      //   "toTimestamp": 1645295399
      // }
      let Service = global.service;
      const promise1 = Service.getOverSpeedingEvents(input);
      const promise2 = Service.getHarshAccelerationEvents(input);
      const promise3 = Service.getHarshBrakingEvents(input);
      const promise4 = Service.getHarshCorneringEvents(input);
      const promise5 = Service.getRunningHours(input);
      const promise6 = Service.getTraveledDistance(input);
      const promise7 = Service.getScheduledStops(scheduledInput);
      const promise8 = Service.getUnscheduledStops(scheduledInput);
      //const promise5 = Service.getCrashEvents(input);
      Promise.all([
        promise1,
        promise2,
        promise3,
        promise4,
        promise5,
        promise6,
        promise7,
        promise8,
      ])
        .then((response) => {
          if (response.length > 0) {
            let data1 = response[0].data
              ? response[0].data.map((ce, index) => ({
                  value: index,
                  label: index,
                  position: [ce["position.latitude"], ce["position.longitude"]],
                  timestamp: ce["timestamp"],
                  overSpeed: ce["overspeeding.speed"],
                  overSpeedingStatus: ce["overspeeding.status"],
                }))
              : [];
            if (data1.length > 0) {
              setOverSpeedingData(data1);
            }
            let data2 = response[1].data
              ? response[1].data.map((ce, index) => ({
                  value: index,
                  label: index,
                  position: [ce["position.latitude"], ce["position.longitude"]],
                  timestamp: ce["timestamp"],
                  harshAccelerationEvent: ce["harsh.acceleration.event"],
                }))
              : [];
            if (data2.length > 0) {
              setHarshAccelerationData(data2);
            }
            let data3 = response[2].data
              ? response[2].data.map((ce, index) => ({
                  value: index,
                  label: index,
                  position: [ce["position.latitude"], ce["position.longitude"]],
                  timestamp: ce["timestamp"],
                  harshBrakingEvent: ce["harsh.braking.event"],
                }))
              : [];
            if (data3.length > 0) {
              setHarshBrakingData(data3);
            }
            let data4 = response[3].data
              ? response[3].data.map((ce, index) => ({
                  value: index,
                  label: index,
                  position: [ce["position.latitude"], ce["position.longitude"]],
                  timestamp: ce["timestamp"],
                  harshCorneringEvent: ce["harsh.cornering.event"],
                  harshCorneringAngle: ce["harsh.cornering.angle"],
                }))
              : [];
            if (data4.length > 0) {
              setHarshCorneringData(data4);
            }
            if (response[4].data && response[4].data) {
              let minutes = parseInt(response[4].data / 60);
              let hours = parseInt(minutes / 60);
              minutes = parseInt(minutes % 60);
              props.getRunningHours(hours + (minutes > 0 ? ":" + minutes : ""));
            }
            if (response[5].data && response[5].data) {
              let mileageData = response[5].data;
              if (mileageData.length > 1) {
                let distance =
                  mileageData[mileageData.length - 1]["vehicle.mileage"] -
                  mileageData[0]["vehicle.mileage"];
                props.getDistanceTravelled(distance);
              }
            }
            let data6 = response[6].data
              ? response[6].data.map((ce, index) => ({
                  value: index,
                  label: index,
                  position: [ce.lat, ce.longd],
                  timestamp: ce.createdAt,
                  totalTime: ce.time,
                  startAt: format(
                    new Date(ce.startAt),
                    "dd-MM-yyyy hh:mm:ss a"
                  ),
                  endAt: format(new Date(ce.endAt), "dd-MM-yyyy hh:mm:ss a"),
                  odometer: ce.odometer,
                }))
              : [];
            if (data6.length > 0) {
              setScheduledData(data6);
            }
            let data7 = response[7].data
              ? response[7].data.map((ce, index) => ({
                  value: index,
                  label: index,
                  position: [ce.lat, ce.longd],
                  timestamp: ce.createdAt,
                  totalTime: ce.time,
                  startAt: format(
                    new Date(ce.startAt),
                    "dd-MM-yyyy hh:mm:ss a"
                  ),
                  endAt: format(new Date(ce.endAt), "dd-MM-yyyy hh:mm:ss a"),
                  odometer: { lat: ce.lat, lng: ce.longd },
                }))
              : [];
            if (data7.length > 0) {
              setUnScheduledData(data7);
            }
            handleDriverBehaviorEvents();
            handleStopEventTypes();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    getDriverBehaviorData(props);
  }, [props.key]);

  const handleDriverBehaviorEvents = () => {
    if (props.DriverBehaviorEventType.indexOf("overSpeedingEvents") > -1) {
      setShowOverSpeedMarkers(true);
    } else {
      setShowOverSpeedMarkers(false);
    }

    if (props.DriverBehaviorEventType.value == "crashEvents") {
      setShowCrashMarkers(true);
    } else {
      setShowCrashMarkers(false);
    }

    if (props.DriverBehaviorEventType.indexOf("harshAccelerationEvents") > -1) {
      setShowHarshAccelerationMarkers(true);
    } else {
      setShowHarshAccelerationMarkers(false);
    }

    if (props.DriverBehaviorEventType.indexOf("harshBrakingEvents") > -1) {
      setShowHarshBrakingMarkers(true);
    } else {
      setShowHarshBrakingMarkers(false);
    }

    if (props.DriverBehaviorEventType.indexOf("harshCorneringEvents") > -1) {
      setShowHarshCorneringMarkers(true);
    } else {
      setShowHarshCorneringMarkers(false);
    }
  };
  useEffect(() => {
    handleDriverBehaviorEvents();
  }, [props.DriverBehaviorEventType]);

  const handleStopEventTypes = () => {
    if (props.StopEventTypes.indexOf("unScheduledEvents") > -1) {
      setShowUnScheduledMarkers(true);
    } else {
      setShowUnScheduledMarkers(false);
    }
    if (props.StopEventTypes.indexOf("scheduledEvents") > -1) {
      setShowScheduledMarkers(true);
    } else {
      setShowScheduledMarkers(false);
    }
  };
  useEffect(() => {
    handleStopEventTypes();
  }, [props.StopEventTypes]);
  const [currentPositionIndex, setCurrentPositionIndex] = useState(0);
  useEffect(() => {
    if(props?.pcsPolyline.length>0){
      const interval = setInterval(() => {
        if (currentPositionIndex < props?.pcsPolyline.length - 1) {
          setCurrentPositionIndex(prevIndex => prevIndex + 1);
        } else {
          clearInterval(interval);
        }
      }, 3000)
  
      return () => clearInterval(interval)
    }

  }, [currentPositionIndex, props?.pcsPolyline]);
  return (
    <div>
      <MapContainer
        key={props.key}
        className={classes.leafletContainer}
        // style={{position: 'relative'}}
        center={props.coordinates[0].position}
        zoom={12}
        scrollWheelZoom={true}
        dragging={true}
        bounds={L.latLngBounds(
          props.coordinates[0].position,
          props.coordinates[props.coordinates.length - 1].position
        )}
      >
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked name="Satellite">
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer checked={false} name="Terrain">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
          {showOverSpeedMarkers
            ? overSpeedingData.map((x, index) => {
                return (
                  <Marker
                    key={index}
                    position={x.position}
                    icon={L.divIcon(SetMarkerDivIcon("os"))}
                  >
                    <Tooltip>
                      <p>
                        {"Over Speed(" +
                          x.position[0] +
                          ", " +
                          x.position[1] +
                          "): " +
                          x.overSpeed +
                          " KMPH"}
                      </p>
                      <p>
                        {"at " + ConvertUnixTimeStampToISTTime(x.timestamp)}
                      </p>
                    </Tooltip>
                  </Marker>
                );
              })
            : null}
          {/* {
          showCrashMarkers ?
          crashData.map((x, index) => {
            return (
              <Marker
                key={index}
                position={x.position}
                icon={L.divIcon(SetMarkerDivIcon("cr"))}
                >
                <Popup>{x}</Popup>
              </Marker>
            )
          }
          ): null
        } */}
          {showHarshAccelerationMarkers
            ? harshAccelerationData.map((x, index) => {
                return (
                  <Marker
                    key={index}
                    position={x.position}
                    icon={L.divIcon(SetMarkerDivIcon("ha"))}
                  >
                    <Tooltip>
                      <p>
                        {"Harsh Acceleration(" +
                          x.position[0] +
                          ", " +
                          x.position[1] +
                          ")"}
                      </p>
                      <p>
                        {"at " + ConvertUnixTimeStampToISTTime(x.timestamp)}
                      </p>
                    </Tooltip>
                  </Marker>
                );
              })
            : null}
          {showHarshBrakingMarkers
            ? harshBrakingData.map((x, index) => {
                return (
                  <Marker
                    key={index}
                    position={x.position}
                    icon={L.divIcon(SetMarkerDivIcon("hb"))}
                  >
                    <Tooltip>
                      <p>
                        {"Harsh Braking(" +
                          x.position[0] +
                          ", " +
                          x.position[1] +
                          ")"}
                      </p>
                      <p>
                        {"at " + ConvertUnixTimeStampToISTTime(x.timestamp)}
                      </p>
                    </Tooltip>
                  </Marker>
                );
              })
            : null}
          {showHarshCorneringMarkers
            ? harshCorneringData.map((x, index) => {
                return (
                  <Marker
                    key={index}
                    position={x.position}
                    icon={L.divIcon(SetMarkerDivIcon("hc"))}
                  >
                    <Tooltip>
                      <p>
                        {"Harsh Cornering Angle(" +
                          x.position[0] +
                          ", " +
                          x.position[1] +
                          "): " +
                          x.harshCorneringAngle.toFixed(2) +
                          " degree"}
                      </p>
                      <p>
                        {"at " + ConvertUnixTimeStampToISTTime(x.timestamp)}
                      </p>
                    </Tooltip>
                  </Marker>
                );
              })
            : null}
          {showScheduledMarkers
            ? scheduledData.map((x, index) => {
                return (
                  <Marker
                    key={index}
                    position={x.position}
                    icon={L.divIcon(SetMarkerDivIcon("scstp"))}
                  >
                    <Tooltip>
                      <p>{"Scheduled stop"}</p>
                      <p>{"Start time: " + x.startAt}</p>
                      <p>{"End time: " + x.endAt}</p>
                      <p>
                        {"Duration: " +
                          (parseInt(x.totalTime / 3600) > 0
                            ? parseInt(x.totalTime / 3600) + "h "
                            : "") +
                          (parseInt((x.totalTime % 3600) / 60) > 0
                            ? parseInt((x.totalTime % 3600) / 60) + "m"
                            : "")}
                      </p>
                    </Tooltip>
                  </Marker>
                );
              })
            : null}
          {showUnScheduledMarkers
            ? unScheduledData.map((x, index) => {
                return (
                  <Marker
                    key={index}
                    position={x.position}
                    icon={L.divIcon(SetMarkerDivIcon("unscstp"))}
                  >
                    <Tooltip className={classes.tooltipText}>
                      <p>{"Unscheduled stop"}</p>
                      <p>{"Start time: " + x.startAt}</p>
                      <p>{"End time: " + x.endAt}</p>
                      <p>{"Latitude: " + x?.odometer?.lat}</p>
                      <p>{"Longitude: " + x?.odometer?.lng}</p>
                      <p>
                        {"Duration: " +
                          (parseInt(x.totalTime / 3600) > 0
                            ? parseInt(x.totalTime / 3600) + "h "
                            : "") +
                          (parseInt((x.totalTime % 3600) / 60) > 0
                            ? parseInt((x.totalTime % 3600) / 60) + "m"
                            : "")}
                      </p>
                    </Tooltip>
                  </Marker>
                );
              })
            : null}
          {props.siteDetails &&
            props.siteDetails.length > 0 &&
            props.siteDetails.map((i, ind) => {
              return i?.geoFenceType === "circle" ? (
                <>
                  <Marker
                    position={[i?.position[0], i?.position[1]]}
                    icon={L.icon({
                      iconUrl:
                        i?.siteType === 4 ? blueMarkerImage : orangeMarkerImage,
                      iconSize: new L.Point(30, 30),
                      iconAnchor: [15, 30],
                    })}
                    key={ind}
                  >
                    <Tooltip style={{ borderRadius: 15 }}>
                      <h1
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          fontWeight: 600,
                          color: i?.siteType === 4 ? "green" : "orange",
                        }}
                      >
                        {i?.siteType === 4 ? "Loading Site" : "Unloading Site"}
                      </h1>
                      <p>{`Name: ${i?.siteName}`}</p>
                      <p style={{}}>{`Address: ${i?.address}`}</p>
                    </Tooltip>
                  </Marker>
                  <Circle
                    center={{
                      lat: i?.position[0],
                      lng: i?.position[1],
                    }}
                    height={"350px"}
                    fillColor="blue"
                    radius={i?.radius}
                  />
                </>
              ) : (
                <>
                  <Marker
                    position={[i?.position[0], i?.position[1]]}
                    icon={L.icon({
                      iconUrl:
                        i?.siteType === 4 ? blueMarkerImage : orangeMarkerImage,
                      iconSize: new L.Point(30, 30),
                      iconAnchor: [15, 30],
                    })}
                    key={ind}
                  >
                    <Tooltip style={{ borderRadius: 15 }}>
                      <h1
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          fontWeight: 600,
                          color: i?.siteType === 4 ? "green" : "orange",
                        }}
                      >
                        {i?.siteType === 4 ? "Loading Site" : "Unloading Site"}
                      </h1>
                      <p>{`Name: ${i?.siteName}`}</p>
                      <p style={{}}>{`Address: ${i?.address}`}</p>
                    </Tooltip>
                  </Marker>
                  <Polygon
                    pathOptions={{ color: "red" }}
                    positions={i?.polygon}
                  />
                </>
              );
            })}
          {/* {props?.pcsData &&
            props?.pcsData?.map((x, index) => {
              // x.position.map((i, index) => {
                return (
                  <Marker
                    key={index}
                    style={{fontSize:40}}
                    // position={props?.pcsPolyline && props?.pcsPolyline[currentPositionIndex]}
                    position={[x.position[0].lat,x.position[0].lng]}

                    // icon={L.divIcon(SetMarkerDivIcon("loaders"))}
                    icon = {x.vehicle.regiNo.includes(`PC`)?L.divIcon(SetMarkerDivIcon("loaders")):L.divIcon(SetMarkerDivIcon(`jcb`))}
                  >
                  </Marker>
                );
              // });
            })} */}
          <LocationMarker
            currentPosition={props.currentPosition}
            coordinates={props.coordinates}
            className={classes.leafletMarkerIcon}
          />
          {/* {props?.pcsPolyline && props?.pcsPositions && props?.pcsPolyline.length>0  ? (
            <Loaders
              currentPosition={props?.pcsPositions && props?.pcsPositions}
              coordinates={props?.pcsPolyline[currentPositionIndex]}
              className={classes.leafletMarkerIcon}
              currentPositionIndex={currentPositionIndex}
            />
          ) : null} */}
        </LayersControl>
      </MapContainer>
    </div>
  );
}

function SetMarkerDivIcon(type) {
  let imagePath = orangeMarkerImage;
  if (type == "os") {
    imagePath = speedIcon;
  } else if (type == "ha") {
    imagePath = accelerationIcon;
  } else if (type == "hb") {
    imagePath = brakeIcon;
  } else if (type == "hc") {
    imagePath = cornerIcon;
  } else if (type == "scstp") {
    imagePath = scheduledStopSvg;
  } else if (type == "unscstp") {
    imagePath = unscheduledStopSvg;
  }else if(type == "loaders"){
    imagePath = excavator
  }else if(type == `jcb`){
    imagePath = JCBicon2
  }else if(type == `loading`){
    imagePath = blueMarkerImage
  }else if(type == `unloading`){
    imagePath = orangeMarkerImage
  }
  return {
    html: `<div style='background: none; border: none; width: 20px; height: 20px; margin-top: -3px;'>
            <img src='${imagePath}' style='width: 100%; height: 100%;' />
      </div>`,
    className: "",
  };
}

function ConvertUnixTimeStampToISTTime(unixTimeStamp) {
  return new Date(unixTimeStamp * 1000).toLocaleString();
}
