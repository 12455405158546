import React, { Fragment } from "react";
import PropTypes from "prop-types";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles, Divider } from "@material-ui/core";
import AntdPagination from "./antd/AntdPagination";
import CustomColumns from "./CustomColumns";
import Table from "@material-ui/core/Table";

const useStyles = makeStyles((theme) => ({
  whiteColor: {
    color: "#fff",
  },
  table: {
    marginTop: "20px",
  },
  checkbox: {
    color: "#fff",
  },
  tableHead: {
    backgroundColor: "#366E93",
  },
  row_paper: {
    background: "#FFFFFF",
    boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
    borderRadius: "4px",
  },
  divider: {
    height: "12px",
  },
}));

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, numSelected, rowCount, headRows } = props;
  return (
    <TableHead className={classes.tableHead}>
      <TableRow>
        <TableCell>
          <Checkbox
            className={classes.checkbox}
            onChange={onSelectAllClick}
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
          />
        </TableCell>
        {headRows.map((row) => (
          <TableCell
            key={row.id}
            align={row.id === "ROUTE" ? "left" : "center"}
            style={{
              padding: row.id === "ROUTE" ? "0px 25px" : "",
              fontWeight: "bold",
              fontSize: "16px",
            }}
            className={classes.whiteColor}
          >
            {row.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  numSelected: PropTypes.number.isRequired,
  rowCount: PropTypes.number.isRequired,
  headRows: PropTypes.array.isRequired,
};

const SelectableTable = (props) => {
  const classes = useStyles();
  const handlePageChange = (page) => {
    props.pageChangeHandler(page);
  };
  const handleChangeRowsPerPage = (size) => {
    props.rowsPerPageChangeHandler(size);
  };
  const handleClick = (event, response) => {
    props.handleClick(event, response);
  };
  const handleSelectAllClick = (event) => {
    props.handleSelectAllClick(event);
  };
  const isSelected = (response, key = "id") => {
    let isSelected = props.selected.filter(
      (res) => res[key] === response[key] && res[key] === response[key]
    );
    if (isSelected.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div>
      <div style={{ padding: "10px 40px 15px 24px", display: "flex" }}>
        <div className="custom_pagination" style={{ flexGrow: 1 }}>
          {props.pagination ? (
            <AntdPagination
              total={parseInt(props.total)}
              current={props.pagination.current}
              pageSize={props.pagination.pageSize}
              handlePageChange={handlePageChange}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          ) : null}
        </div>
        {props.cols ? (
          <CustomColumns
            columns={props.cols}
            checkApplyHandler={props.columnCheckHandler}
          />
        ) : null}
      </div>
      <div className="custom_table">
        <Table className={classes.table}>
          <EnhancedTableHead
            classes={classes}
            onSelectAllClick={handleSelectAllClick}
            numSelected={props?.selected?.length}
            rowCount={
              props.tableData.length > 0 ? props.tableData.length : null
            }
            headRows={props.headRows}
          />
          <TableBody>
            {props.tableData.length > 0 &&
              props.tableData.map((res, index) => {
                const isItemSelected = isSelected(res, props.uniqueKey);
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <Fragment key={index}>
                    <TableRow
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                      className={classes.row_paper}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ "aria-labelledby": labelId }}
                          onChange={(event) => {
                            handleClick(event, res);
                          }}
                        />
                      </TableCell>
                      {Object.keys(res).map((key, j) => {
                        return (
                          <TableCell key={j} align="center">
                            {res[`${key}`]}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                    <Divider className={classes.divider} />
                  </Fragment>
                );
              })}
          </TableBody>
        </Table>
      </div>
      {props.tableData.length === 0 ? (
        <div
          style={{ textAlign: "center", height: "50vh", position: "relative" }}
        >
          <span
            style={{
              position: "absolute",
              top: "60%",
              left: "46%",
              fontSize: "20px",
            }}
          >
            No Data Found!
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default SelectableTable;
