import { Snackbar, makeStyles } from "@material-ui/core";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  CircularProgress,
} from "@material-ui/core";
import { Grid } from "@mui/material";
import Header from "../../components/header";
import { Alert, Typography } from "antd";
import AlertMessage from "../../components/alertmessage/AlertMessage";
import { ArrowBack } from "@material-ui/icons";
import _, { max } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import moment from "moment";
import * as Fields from "../../sharedComponents";
import CircularLoading from "../../components/loader/circularLoading";
// import { backgroundClip } from "html2canvas/dist/types/css/property-descriptors/background-clip";
import { apartment } from "../../assets/index";
import { deviceInstallDate } from "../../assets/index";
import { deviceSerialNumber } from "../../assets/index";
import { deviceStatus } from "../../assets/index";
import { deviceType } from "../../assets/index";
import { serviceProvider } from "../../assets/index";
import { simActivateDate } from "../../assets/index";
import { simBasketName } from "../../assets/index";
import { simNumber } from "../../assets/index";
import { subscriptionDate } from "../../assets/index";
import { subscriptionExpiryDate } from "../../assets/index";
import { subscriptionRate } from "../../assets/index";
import { subscriptionStatus } from "../../assets/index";
import { subscriptionType } from "../../assets/index";
import { whitelistingNumber } from "../../assets/index";
import { deviceImei } from "../../assets/index";
import { devicePurchaseDate } from "../../assets/index";
import { rightImei } from "../../assets/index";
import { wrongImei } from "../../assets/index";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Util from "../../utils";
import ValidateFields from "../../validations/validateFields";
import { InputAdornment } from '@material-ui/core';
// import { image } from "html2canvas/dist/types/css/types/image";

const useStyles = makeStyles((theme) => ({
  alertBox: {
    padding: "10px 12px",
  },
  input_adorment: { padding: "0px 15px 0px 2px" },
}));

export const AddDevice = () => {
  const util = new Util()
  const validateFields = new ValidateFields()
  const formFields = {
    deviceImei: {
      name: "deviceImei",
      label: "Device(IMEI)",
      value: "",
      isValid: true,
      topLabel: true,
      validationRequired: true,
      validPattern: "NUMERIC",
      errorMsg: "Please enter device imei number",
      maxLength: 4,
      min: 0,
      max: 1000000000000000,
      // ref: useRef(),
      type: "number",
      labelColor: "#397296",
      image: deviceImei,
    },
    // msisdn: {
    //   name: "msisdn",
    //   label: "MSISDN",
    //   value: "",
    //   isValid: true,
    //   topLabel: true,
    //   validationRequired: true,
    //   validPattern: "NUMERIC",
    //   errorMsg: "Please enter msisdn number",
    //   ref: useRef(),
    //   type: "number",
    //   labelColor: "#397296",
    // },
    // simNumber: {
    //   name: "simNumber",
    //   label: "Sim Number",
    //   value: "",
    //   isValid: true,
    //   topLabel: true,
    //   validationRequired: true,
    //   validPattern: "NUMERIC",
    //   errorMsg: "Please enter sim number",
    //   type: "number",
    //   ref: useRef(),
    //   labelColor: "#397296",
    //   image: simNumber,
    // },
    // simBasket: {
    //   name: "simBasket",
    //   label: "Sim Basket Name",
    //   value: "",
    //   isValid: true,
    //   topLabel: true,
    //   validationRequired: true,
    //   validPattern: "ALPHA_NUMERIC_SPACE",
    //   errorMsg: "Please enter sim basket name",
    //   ref: useRef(),
    //   labelColor: "#397296",
    //   image: simBasketName,
    // },
    // simActivate: {
    //   name: "simActivate",
    //   label: "Sim Activate Date",
    //   value: moment(new Date()),
    //   validationRequired: true,
    //   isValid: true,
    //   errorMsg: "Please select activate date",
    //   validPattern: "DATE",
    //   topLabel: true,
    //   ref: useRef(),
    //   labelColor: "#397296",
    //   image: simActivateDate,
    //   // disabledDate: (d) => !d || d.isBefore(new Date()),
    // },
    // whitelistNum: {
    //   name: "whitelistNum",
    //   label: "Whitelisting Number",
    //   value: "",
    //   isValid: true,
    //   topLabel: true,
    //   validationRequired: true,
    //   validPattern: "NUMERIC",
    //   errorMsg: "Please enter whitelist number",
    //   ref: useRef(),
    //   type: "number",
    //   labelColor: "#397296",
    //   image: whitelistingNumber,
    // },
    companyName: {
      name: "companyName",
      label: "Company Name",
      value: { value: "", label: "" },
      options: [],
      topLabel: true,
      validationRequired: true,
      validPattern: "ALPHABET_with_numeric_label",
      isValid: true,
      errorMsg: "Please select company name",
      ref: useRef(),
      allowClear: true,
      labelColor: "#397296",
      image: apartment,
    },
    // damageReason: {
    //   name: "damageReason",
    //   label: "Damage Reason",
    //   value: "",
    //   isValid: true,
    //   topLabel: true,
    //   validationRequired: true,
    //   validPattern: "ALPHABET_with_numeric_label",
    //   errorMsg: "Please enter damage reason",
    //   ref: useRef(),
    //   labelColor: "#397296",
    // },
    deviceSrNo: {
      name: "deviceSrNo",
      label: "Device Serial Number",
      value: "",
      isValid: true,
      topLabel: true,
      validationRequired: true,
      validPattern: "NUMERIC",
      errorMsg: "Please enter serial number",
      ref: useRef(),
      type: "number",
      labelColor: "#397296",
      image: deviceSerialNumber,
    },
    devicePurDate: {
      name: "devicePurDate",
      label: "Device Purchase Date",
      value: moment(new Date()),
      validationRequired: true,
      isValid: true,
      errorMsg: "Please select purchase date",
      validPattern: "DATE",
      topLabel: true,
      ref: useRef(),
      labelColor: "#397296",
      image: devicePurchaseDate,
      // disabledDate: (d) => !d || d.isBefore(new Date()),
    },
    deviceManfBy: {
      name: "deviceManfBy",
      label: "Service Provider",
      value: { value: "", label: "" },
      options: [],
      topLabel: true,
      validationRequired: true,
      validPattern : 'ALPHABET_with_numeric_label',
      isValid: true,
      errorMsg: "Please select manufacture Name",
      ref: useRef(),
      allowClear: true,
      labelColor: "#397296",
      image: serviceProvider,
    },
    deviceType: {
      name: "deviceType",
      label: "Device Type",
      value: { value: "", label: "" },
      options: [],
      topLabel: true,
      validationRequired: true,
      validPattern : 'ALPHABET_with_numeric_label',
      isValid: true,
      errorMsg: "Please select device Type",
      ref: useRef(),
      allowClear: true,
      labelColor: "#397296",
      image: deviceType,
    },
    // subscriptionType: {
    //   name: "subscriptionType",
    //   label: "Subscription Type",
    //   value: { value: "", label: "" },
    //   options: [
    //     { value: "Monthly", label: "Monthly" },
    //     { value: "Quarterly", label: "Quarterly" },
    //     { value: "Annually", label: "Annually" },
    //   ],
    //   topLabel: true,
    //   validationRequired: true,
    //   validPattern : 'ALPHABET_with_numeric_label',
    //   isValid: true,
    //   errorMsg: "Please select subscription type",
    //   ref: useRef(),
    //   allowClear: true,
    //   labelColor: "#397296",
    //   image: subscriptionType,
    // },
    // subscriptionRate: {
    //   name: "subscriptionRate",
    //   label: "Subscription Price",
    //   value: "",
    //   isValid: true,
    //   topLabel: true,
    //   validationRequired: true,
    //   validPattern: "NUMERIC",
    //   errorMsg: "Please enter subscription price",
    //   ref: useRef(),
    //   type: "number",
    //   labelColor: "#397296",
    //   image: subscriptionRate,
    // },
    // subscriptionStatus: {
    //   name: "subscriptionStatus",
    //   label: "Subscription Status",
    //   value: { value: "", label: "" },
    //   options: [
    //     { value: "1", label: "Active" },
    //     { value: "0", label: "In Active" },
    //   ],
    //   topLabel: true,
    //   validationRequired: true,
    //   validPattern : 'ALPHABET_with_numeric_label',
    //   isValid: true,
    //   errorMsg: "Please select subscription status",
    //   ref: useRef(),
    //   allowClear: true,
    //   labelColor: "#397296",
    //   image: subscriptionStatus,
    // },
    status: {
      name: "status",
      label: "Device Status",
      value: { value: "", label: "" },
      options: [
        { value: "1", label: "Active" },
        { value: "0", label: "In Active" },
      ],
      topLabel: true,
      validationRequired: true,
      validPattern : 'ALPHABET_with_numeric_label',
      isValid: true,
      errorMsg: "Please select device status",
      ref: useRef(),
      allowClear: true,
      labelColor: "#397296",
      image: deviceStatus,
    },
    installDate: {
      name: "installDate",
      label: "Device Install Date",
      value: moment(new Date()),
      validationRequired: true,
      isValid: true,
      errorMsg: "Please select install date",
      validPattern: "DATE",
      topLabel: true,
      ref: useRef(),
      labelColor: "#397296",
      image: deviceInstallDate,
      // disabledDate: (d) => !d || d.isBefore(new Date()),
    },
    // subscriptionDate: {
    //   name: "subscriptionDate",
    //   label: "Subscription Date",
    //   value: moment(new Date()),
    //   validationRequired: true,
    //   isValid: true,
    //   errorMsg: "Please select subscription date",
    //   validPattern: "DATE",
    //   topLabel: true,
    //   ref: useRef(),
    //   labelColor: "#397296",
    //   image: subscriptionDate,
    //   // disabledDate: (d) => !d || d.isBefore(new Date()),
    // },
    // subscriptionExpDate: {
    //   name: "subscriptionExpDate",
    //   label: "Subscription Expiry Date",
    //   value: moment(new Date()),
    //   validationRequired: true,
    //   isValid: true,
    //   errorMsg: "Please select expiry date",
    //   validPattern: "DATE",
    //   topLabel: true,
    //   ref: useRef(),
    //   labelColor: "#397296",
    //   image: subscriptionExpiryDate,
    //   // disabledDate: (d) => !d || d.isBefore(new Date()),
    // },
    // dateOfExpiry: {
    //   name: "dateOfExpiry",
    //   label: "Expiry Date",
    //   value: moment(new Date()),
    //   validationRequired: true,
    //   isValid: true,
    //   errorMsg: "Please select expiry date",
    //   validPattern: "DATE",
    //   topLabel: true,
    //   ref: useRef(),
    //   labelColor: "#397296",
    //   // disabledDate: (d) => !d || d.isBefore(new Date()),
    // },
  };
  const [fields, setFields] = useState(formFields);
  let Service = global.service;
  const [deviceChecker,setDeviceChecker] = useState(null)
  useEffect(async () => {
    let newField = _.cloneDeep(fields);
    await Service.getDeviceProviders()
      .then((res) => {
        if (res.deviceProviders.length > 0) {
          let options = res.deviceProviders.map((each) => {
            return { label: each.name, value: each.id };
          });
          newField.deviceManfBy.options = options;
        }
      })
      .catch((e) => {
        console.log("Error.......", e);
      });
    await Service.getCompanies()
      .then((res) => {
        let companies = res.data.map((each) => {
          return { label: each.companyName, value: each.id };
        });
        newField.companyName.options = companies;
      })
      .catch((e) => {
        console.log("Error.......", e);
      });
    setFields(newField);
  }, []);

  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [snack, setSnack] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const snackClose = () => {
    let newSnack = _.cloneDeep(snack);
    newSnack.open = false;
    newSnack.message = "";
    setSnack(newSnack);
  };
  const classes = useStyles();
  const history = useHistory();
  //   const decimalValidation = (value) => {
  //     const regex = new RegExp("^[0-9]+(.[0-9]{1,2})?$");
  //     return regex.test(value);
  //   };
  //   const otherThenalphaNumericValidation = (value) => {
  //     const regex = new RegExp("^[a-zA-Z0-9@]+(.)?[a-z]*$");
  //     return regex.test(value);
  //   };
  const inputChangeHandler = (value, name) => {
    let newFields = _.cloneDeep(fields);
    if(name == 'deviceImei' && value.length != 15){
      setDeviceChecker(false)
    }
    if(name == 'deviceImei' && value.length == 15){
      Service.checkDevice(value).then((res)=>{
        setDeviceChecker(res.status)
      }).catch(e=>{
        console.log('Error.....',e)
      })
    }
    if (newFields[name]["validationRequired"] && value.length > 0) {
      newFields[name]["isValid"] = true;
    }
    newFields[name]["value"] = value;
    // if(name == 'rent' || name == 'dieselMileage' || name == 'dieselConsumption' || name == 'gateNum'){
    //   if (
    //     decimalValidation(value) === true ||
    //     value === "" ||
    //     otherThenalphaNumericValidation(value) === true
    //   ) {
    //     newFields[i][name]["value"] = value;
    //     newFields[i][name]["isValid"] = false;
    //   }
    // }else{
    //   newFields[i][name]["value"] = value;
    // }
    setFields(newFields);
  };
  const autoCompleteChangeHandler = async (value, name) => {
    let newFields = _.cloneDeep(fields);
    if (newFields[name]["validationRequired"] && value.label.length > 0) {
      newFields[name]["isValid"] = true;
    }
    if (name == "deviceManfBy" && value?.value != "") {
      await Service.getDeviceTypes(value?.value).then((res) => {
        if (res.deviceTypes.length > 0) {
          let options = res.deviceTypes.map((each) => {
            return { label: each.name, value: each.id,deviceTypeId : each.flespiDeviceTypeId };
          });
          newFields.deviceType.options = options;
          // setFields(newFields)
        }
      });
    } else if (name == "deviceManfBy" && value?.value == "") {
      newFields.deviceType.options = [];
    }
    newFields[name]["value"] = value;
    setFields(newFields);
  };

  const timeStampChangeHandler = (value, name) => {
    let newFields = _.cloneDeep(fields);
    newFields[name]["value"] = value;
    // formData[name]["isValid"] = true;
    setFields(newFields);
  };
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };
  const [loader, setLoader] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const addDeviceDetails = async() => {
    // let newFields = _.cloneDeep(fields);
    // if(!deviceChecker || fields?.deviceImei?.value.length != 15){
    //     fields.deviceImei.isValid = false
    //     fields.deviceImei.errorMsg = 'Please enter correct IMEI number'
    //     setFields(newFields);
    // }
    let dataStatus = await validateFields.validateFieldsData(fields) 
    if(dataStatus.status && deviceChecker){
      setSpinner(true);
      let deviceData = {
        companyId: fields.companyName?.value?.value,
        serviceProviderId: fields.deviceManfBy.value?.value,
        deviceType: String(fields.deviceType.value?.value),
        flespiDeviceType: fields.deviceType.value?.deviceTypeId,
        deviceImei: fields.deviceImei.value,
        deviceSrNum: fields.deviceSrNo.value,
        devicePurDate: util.formatDate(fields.devicePurDate.value),
        deviceInstallDate: util.formatDate(fields.installDate.value),
        deviceStatus: fields.status.value.value,
      };
      await Service.addDevice(deviceData).then((res) => {
        if (res.status == true) {
              setAlertData({
                open: true,
                message:res.message ,
                severity: "success",
              });
              // window.location.reload(); 
              setSpinner(false); 
        } else {
          setAlertData({
            open: true,
            message: res.message,
            severity: "error",
          });
          setSpinner(false);
        }
      });

    }else{
      let newFields = _.cloneDeep(fields);
      let erroredFields = Object.keys(dataStatus.data).filter(
        (key, i) => dataStatus.data[key].isValid === false
      );
      newFields = dataStatus.data;
      setFields(newFields);
    }
   
  };
  return (
    <>
      <div>
        <Header />
        <div className="main_container">
          <div className="header_box">
            <Typography className="header_text" 
            // style={{ color: "#397296" }}
            >
              <ArrowBack
                className="arrow"
                onClick={() => history.goBack()}
                // style={{ color: "#F15F4C" }}
              />
              Add Device
              {/* {props.location.state ? "Update Group" : "Add Group"} */}
            </Typography>
            {/* <div className={classes.header_buttons}>
              <Button
                variant="contained"
                className={classes.addmember_btn}
                color="primary"
                startIcon={<AddCircleOutlinedIcon />}
                onClick={() => {
                  setDrawer(true);
                }}
              >
                Add Members
              </Button>
              <Drawer open={drawer} anchor="right" variant="persistent">
                <div style={{ width: "650px" }}>
                  <div className="drawer_header">
                    <Typography variant="subtitle1">Select Members</Typography>
                    <CloseIcon
                      style={{ alignSelf: "center", cursor: "pointer" }}
                      onClick={() => {
                        membersRef.current.setMembers();
                        setDrawer(false);
                      }}
                    />
                  </div>
                  <Members
                    memberHandler={memberHandler}
                    ref={membersRef}
                    selectedMembers={selectedMembers}
                    drawer={drawer}
                  />
                </div>
              </Drawer>
            </div> */}
          </div>
          {alertData.open ? (
            <div className={classes.alertBox}>
              <AlertMessage
                severity={alertData.severity}
                message={alertData.message}
                closeAlert={closeAlert}
              />
            </div>
          ) : null}
          {loader ? (
            <CircularLoading />
          ) : (
            <div
              className="form_container"
              style={{ backgroundColor: "#F7FCFF" }}
            >
              <div style={{ padding: "10px" }}>
                <Typography
                  className="details_container_heading"
                  style={{
                    color: "#F15F4C",
                    fontWeight: "600",
                    fontSize: "25px",
                    width: "180px",
                  }}
                >
                  Device Details
                </Typography>
                <Grid
                  container
                  spacing={3}
                  className="details_container_content"
                >
                  <Grid item xs={6}>
                    <Fields.AntSelectableSearchField
                      fieldData={fields.companyName}
                      autoCompleteChangeHandler={(value, name) =>
                        autoCompleteChangeHandler(value, name)
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Fields.AntSelectableSearchField
                      fieldData={fields.deviceManfBy}
                      autoCompleteChangeHandler={(value, name) =>
                        autoCompleteChangeHandler(value, name)
                      }
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  className="details_container_content"
                >
                  <Grid item xs={4}>
                    <Fields.AntSelectableSearchField
                      fieldData={fields.deviceType}
                      autoCompleteChangeHandler={(value, name) =>
                        autoCompleteChangeHandler(value, name)
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Fields.InputField
                      fieldData={fields.deviceImei}
                      inputChangeHandler={inputChangeHandler}
                      variant="outlined"
                      endAdornment={
                        <InputAdornment style={{ backgroundColor: "#777777" }}>
                         {deviceChecker === true ? (
          <img src={rightImei} alt="right" style={{ height: '35px', width: '35px' }} />
        ) : deviceChecker === false ? (
          <img src={wrongImei} alt="wrong" style={{ height: '35px', width: '35px' }} />
        ) : null}
                        </InputAdornment>
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Fields.InputField
                      fieldData={fields.deviceSrNo}
                      inputChangeHandler={inputChangeHandler}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  spacing={2}
                  className="details_container_content"
                >
                  <Grid item xs={4}>
                    <Fields.AntdDatePickerField
                      fieldData={fields.devicePurDate}
                      timeStampChangeHandler={timeStampChangeHandler}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Fields.AntdDatePickerField
                      fieldData={fields.installDate}
                      timeStampChangeHandler={timeStampChangeHandler}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Fields.AntSelectableSearchField
                      fieldData={fields.status}
                      autoCompleteChangeHandler={(value, name) =>
                        autoCompleteChangeHandler(value, name)
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={3}>
              <Fields.AntdDatePickerField
                fieldData={fields.dateOfExpiry}
                timeStampChangeHandler={timeStampChangeHandler}
              />
            </Grid> */}
                </Grid>
                <div>
                  {/* <Typography
                    className="details_container_heading"
                    style={{
                      color: "#F15F4C",
                      fontWeight: "600",
                      fontSize: "25px",
                      width: "180px",
                      paddingTop: "10px",
                    }}
                  >
                    Sim Details
                  </Typography> */}
                  {/* <Grid
                    container
                    spacing={2}
                    className="details_container_content"
                  >
                    <Grid item xs={3}>
                      <Fields.InputField
                        fieldData={fields.simNumber}
                        inputChangeHandler={inputChangeHandler}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Fields.InputField
                        fieldData={fields.whitelistNum}
                        inputChangeHandler={inputChangeHandler}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Fields.InputField
                        fieldData={fields.simBasket}
                        inputChangeHandler={inputChangeHandler}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <Fields.AntdDatePickerField
                        fieldData={fields.simActivate}
                        timeStampChangeHandler={timeStampChangeHandler}
                      />
                    </Grid>
                   
                  </Grid> */}
                </div>
                <div>
                  {/* <Typography
                    className="details_container_heading"
                    style={{
                      color: "#F15F4C",
                      fontWeight: "600",
                      fontSize: "25px",
                      width: "180px",
                      paddingTop: "10px",
                    }}
                  >
                    Subscription Details
                  </Typography> */}
                  <Grid
                    container
                    spacing={2}
                    className="details_container_content"
                  >
                    {/* <Grid item xs={2.4}>
                    <Fields.InputField
                      fieldData={fields.whitelistNum}
                      inputChangeHandler={inputChangeHandler}
                      variant="outlined"
                    />
                  </Grid> */}

                    {/* <Grid item xs={4}>
                      <Fields.AntSelectableSearchField
                        fieldData={fields.subscriptionType}
                        autoCompleteChangeHandler={(value, name) =>
                          autoCompleteChangeHandler(value, name)
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Fields.AntSelectableSearchField
                        fieldData={fields.subscriptionStatus}
                        autoCompleteChangeHandler={(value, name) =>
                          autoCompleteChangeHandler(value, name)
                        }
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Fields.AntdDatePickerField
                        fieldData={fields.subscriptionDate}
                        timeStampChangeHandler={timeStampChangeHandler}
                      />
                    </Grid> */}
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className="details_container_content"
                  >
                    {/* <Grid item xs={4}>
                      <Fields.AntdDatePickerField
                        fieldData={fields.subscriptionExpDate}
                        timeStampChangeHandler={timeStampChangeHandler}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <Fields.InputField
                        fieldData={fields.subscriptionRate}
                        inputChangeHandler={inputChangeHandler}
                        variant="outlined"
                      />
                    </Grid> */}
                  </Grid>
                </div>
              </div>
              <div>
                <Grid
                  container
                  spacing={3}
                  className="details_container_content"
                >
                  <Grid item xs={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={8}></Grid>
                      <Grid item xs={2}>
                        <Button
                          variant="outlined"
                          className="cancel_button"
                            onClick={() => history.goBack()}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          variant="contained"
                          className="save_button"
                          //   onClick={() => {
                          //     if (props.location.state) {
                          //     //   updateGroupHandler();
                          //     } else {
                          //       addGroupHandler();
                          //     }
                          //   }}
                          onClick={addDeviceDetails}
                          startIcon={
                            spinner ? (
                              <CircularProgress size={20} color={"#fff"} />
                            ) : null
                          }
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
