import Axios from 'axios';

const axios = Axios.create({
    // baseURL :'http://65.0.133.20:3080/',
    timeout : 180000
})

axios.interceptors.request.use(async (config) => {
    if(global.session.get('BearerToken')){
        let token = global.session.get('BearerToken');
        config.headers['Authorization'] = `Bearer ${token}`        
        return config;
    }
    return config;   
})
export default axios;