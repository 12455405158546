import BankRequestBuilders from "../RequestBuilders/banks";

class Devices {
  constructor(Service, path, Util) {
    this.httpService = Service;
    this.path = path;
    this.Util = Util;
    this.bankRequestBuilder = new BankRequestBuilders();
  }

  getDeviceProviders = () => {
    const url =
      process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/devices/deviceProviders`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getDeviceTypes = (data) => {
    const url =
    process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/devices/${data}/deviceTypes`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getCompanies = () => {
    const url =
    process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/devices/companiesList`
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  checkDevice = (data) =>{
      const url =  process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/devices/checkDevice?imei=${data}`
      return new Promise((resolve, reject) => {
        this.httpService
          .get(url)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }

    getMyDevicesList = (data) => {
      let s = "";
      Object.keys(data).map((i) => {
        s += `${i}=${data[i]}&`;
      });
      let formateData = s.slice(0, -1);
       const url =
       process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/devices/myDevicesList?${formateData}`;
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })  
        .catch((error) => {
          reject(error);
        });
    });
  };

  getVehiclesByCompanyId = (data) =>{
    const url =  process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + `/devices/truckList/${data}`
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  assignDeviceToTruck = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3  + "/devices/assignTruck"
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  transferDevice = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + "/devices/transferDevice"
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  deleteDevice = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + "/devices/deleteDevice"
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  damageDevice = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + "/devices/damageData"
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  damageDeviceImage = (data) => {
    const url =
    process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + "/devices/damageDeviceImage"
    return new Promise((resolve, reject) => {
      this.httpService
        .patch(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  
  addDevice = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + "/devices/addDevice"
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  moveDeviceToSubaccount = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + "/devices/movedToSubAccount"
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateLostDevice = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + "/devices/lostDevice"
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  getDevicesByCompanyId = (data) =>{
    const url = data != null && data != undefined && data != "" ? process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/devices/devices?companyId=${data}` : process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/devices/devices`
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  authenticateTableau = () =>{
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 +`/devices/tableau`
    return new Promise((resolve, reject) => {
      this.httpService
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateSimToDevice = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + "/devices/assignSim"
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  updateSubscriptionToDevice = (data) => {
    const url = process.env.REACT_APP_SUVEGA_API_ENDPOINT_3 + "/devices/deviceSubscription"
    return new Promise((resolve, reject) => {
      this.httpService
        .post(url, data)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  
}

export default Devices