import {
  Typography,
  IconButton,
  Paper,
  TextField,
  Divider,
  Button,
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import { truckImage as truck } from "../../assets/index";
import SearchIcon from "@material-ui/icons/Search";
import TrackingMap from "./map/index";
import {
  MobileDateAndTimePicker,
  AutoSelect,
  DateRangePicker,
} from "../../components/index";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Header from "../../components/header";
import DriverBehaviorSelect from "./map/driverBehavior";
import VehicleStops from "./map/vehiclesStops";
import queryString from "query-string";
// import { parse } from "qs";
import { useLocation } from "react-router-dom";
import moment from "moment";
// import TabsUnstyled from '@mui/base/TabsUnstyled';
// import TabsListUnstyled from '@mui/base/TabsListUnstyled';
// import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
// import TabUnstyled from '@mui/base/TabUnstyled';
import FuelHistoryContent from "./fuelHistory";
import TabButton from "./tab-button";
import { Tabs } from "antd";
import "./tabs.css";
import Map from "../clients/contracts/routes/Map";
import { ArrowBack } from "@material-ui/icons";

// import { parse } from "date-fns";
const { TabPane } = Tabs;

const driverBehaviorEvents = [
  { value: "overSpeedingEvents", text: "Over Speeding" },
  //   {value: 'crashEvents', text: 'Crash Events'},
  { value: "harshAccelerationEvents", text: "Harsh Acceleration" },
  { value: "harshBrakingEvents", text: "Harsh Braking" },
  { value: "harshCorneringEvents", text: "Harsh Cornering" },
];
const stopEvents = [
  { value: "unScheduledEvents", text: "Unscheduled Stops" },
  { value: "scheduledEvents", text: "Scheduled Stops" },
];
var Service;
const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: "#366E93",
  },
  grow: {
    flexGrow: "1",
    marginLeft: theme.spacing(2),
  },
  image: {
    width: "120px",
  },
  name: {
    marginRight: theme.spacing(1),
    fontSize: "0.9rem",
  },
  content: {
    display: "flex",
  },
  list: {
    color: "#366E93",
    fontWeight: "bold",
    display: "flex",
    marginTop: "10px",
    textAlign: "left",
  },
  middle: {
    width: "100%",
    paddingBottom: 8,
    paddingTop: 8,
    justifyContent: "space-between",
  },
}));

export default function Dashboard(props) {
  const fieldData = {
    pickupLocation: {
      name: "pickupLocation",
      label: "",
      value: "",
      topLabel: true,
      validationRequired: true,
      isValid: true,
      validPattern: "SPECIAL_CHARS_DESC",
      lat: null,
      lng: null,
      errorMsg: "Please select pickup location",
      ref: useRef(),
    },
    trucks: {
      name: "trucks",
      isValid: true,
      value: { label: "", value: "" },
      label: "",
      options: [],
      topLabel: true,
      validationRequired: true,
      errorMsg: "Please select client",
      validPattern: "SPECIAL_CHARS_DESC",
    },
  };
  const history = useHistory();
  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;
  const [mapHeight, setMapHeight] = useState(0);
  const firstVehicleButtonRef = useRef();
  const classes = useStyles();
  const [vehicles, setVehicles] = React.useState([]);
  const [
    previouslySelectedVehicleListItem,
    setPreviouslySelectedVehicleListItem,
  ] = React.useState(null);
  const [selectedVehiceNum, setSelectedVehicleNum] = React.useState(null);
  const [trucks, setSearch] = useState(vehicles);
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [trucksInfo, setTrucksInfo] = useState([]);
  const [ownerId, setOwnerId] = React.useState(null);
  const [fromDateToTrack, setFromDateToTrack] = React.useState(
    new Date(new Date().setHours(0, 0, 0, 0))
  );
  const [toDateToTrack, setToDateToTrack] = React.useState(
    new Date(new Date().setHours(23, 59, 59, 0))
  );
  const [dateRangeDefaultValue, setDateRangeDefaultValue] = React.useState([
    moment().startOf("day"),
    moment().endOf("day"),
  ]);
  const [driverBehaviorEventType, setDriverBehaviorEventType] = React.useState(
    []
  );
  const [stopEventTypes, setStopEventTypes] = React.useState(
    stopEvents.map((item) => item.value)
  );
  const [getVehicle, setGetVehicle] = React.useState();
  const [errorMessage, setErrorMessage] = React.useState("Loading...");
  const [vehicleDropdownIndex, setVehicleDropdownIndex] = React.useState(0);
  const [distanceTravelled, setDistanceTravelled] = React.useState("-");
  const [runningHours, setRunningHours] = React.useState("-");
  let { search } = useLocation();
  const [hideHeader, setHideHeader] = React.useState(false);
  const [flVehicles, setFuelVehicles] = React.useState([]);
  const [vehilcePositionTimestamp, setVehilcePositionTimestamp] = useState("");
  const [fields, setFields] = useState(fieldData);

  const resetMapHeight = () => {
    let val =
      winWidth < 640
        ? winHeight - 472
        : winWidth < 700
        ? winHeight - 420
        : winWidth < 1035
        ? winHeight - 365
        : winHeight - 310;
    if (global.session.get("hideHeader")) {
      val = val + 65;
      setMapHeight(val);
    } else {
      setMapHeight(val);
    }
  };
  // const getOwnerLatLng = parse(global.session.set("profile"))

  const getVechileLocationDetails = (id) => {
    const promise2 = Service.getDashboardTrucks();
    Promise.all([promise2]).then((response) => {});
  };
  const [getSiteInfo, setSiteInfo] = useState();

  useEffect(() => {
    // let tokenFromQueryString = null;
    // const query = parse(props.location.search, {
    //     ignoreQueryPrefix: true
    // })
    // if (query && query.token && query.token.length > 0) {
    //     let tokenFromQueryString = query.token.replace(/ /g, "+");
    //     global.session.set('BearerToken', tokenFromQueryString);
    //     if (query.vehicle) {
    //         window.location = '/dashboard?vehicle=' + query.vehicle;
    //     } else {
    //         window.location = '/dashboard';
    //     }
    // }

    if (global.session.get("hideHeader")) {
      setHideHeader(true);
    }
    if (global.session.get("BearerToken")) {
      // let data = {siteTypeIds:[4, 5]}
      Service = global.service;
      const promise1 = Service.getFleetOwnerProfile();
      const promise2 = Service.getDashboardTrucks();
      const promise3 = Service.getSites({ siteTypeIds: [4, 5] });
      Promise.all([promise1, promise2, promise3])
        .then((response) => {
          if (response[0].status) {
            let newFields = { ...fields };
            global.session.set("profile", response[0].profile);
            newFields["pickupLocation"].lat = response[0].profile.lat;
            newFields["pickupLocation"].lng = response[0].profile.longi;
            setFields(newFields);
            setOwnerId(response[0].profile.id);
          } else {
            global.session.remove("profile");
            setErrorMessage("owner does not exist");
            return;
          }
          if (response[1].status) {
            if (response[1].trucks.length > 0) {
              let newFields = { ...fields };
              let vehicleList = response[1].trucks.map((x) => x.regi_no);
              newFields["trucks"].options = response[1].trucks.map((x) => {
                return {
                  label: x.regi_no,
                  value: x.id,
                };
              });
              setFields(newFields);

              const fuelVehiclesList = response[1].trucks
                .filter((f) => f.fuel_sensor == 1)
                .map((x) => x.regi_no);
              setFuelVehicles(fuelVehiclesList);
              setVehicles(vehicleList);
              resetMapHeight();
              let location = props.location;
              let vehiclleSelected = false;
              try {
                if (
                  location &&
                  location.search &&
                  location.search.toLowerCase().indexOf("vehicle") > -1
                ) {
                  let params = queryString.parse(location.search);
                  let vehiclenum = params["vehicle"];
                  let vehicleToFindList = response[1].trucks.filter(
                    (x) => x.regi_no.toUpperCase() == vehiclenum.toUpperCase()
                  );
                  if (vehicleToFindList.length > 0) {
                    setSelectedVehicleNum(vehicleToFindList[0].regi_no);
                    setSelectedVehicle(vehicleToFindList[0]);
                    setVehicleDropdownIndex(
                      vehicleList.indexOf(vehicleToFindList[0].regi_no)
                    );
                    vehiclleSelected = true;
                  }
                }
              } catch (err) {
                console.log(err);
              }
              if (!vehiclleSelected) {
                setSelectedVehicleNum(vehicleList[0]);
                // setSelectedVehicle(response[1].trucks[0]);
              }
              setSearch(vehicleList);
              setTrucksInfo(response[1]?.trucks);
              // firstVehicleButtonRef.current.click();
            } else {
              setErrorMessage("no vehicles found");
            }
          }
          if (response[2]) {
            let data =
              response[2]?.sites &&
              response[2]?.sites?.map((ce, index) => ({
                value: index,
                label: index,
                position: [ce.latitude, ce.longitude],
                geoFenceType: ce.geoFenceType,
                polygon: ce.polygon,
                radius: ce.radius,
                address: ce.address,
                siteName: ce.siteName,
                siteType: ce.siteType,
              }));
            setSiteInfo(data);
          }
        })
        .catch((err) => {
          setErrorMessage("Error in connecting to Server", err);
        });
    } else {
      window.location = "/";
    }
  }, []);

  const setPreviousDate = () => {
    let fromDate = new Date(
      new Date(fromDateToTrack).setDate(new Date(fromDateToTrack).getDate() - 1)
    );
    let toDate = new Date(
      new Date(toDateToTrack).setDate(new Date(toDateToTrack).getDate() - 1)
    );
    setFromDateToTrack(fromDate);
    setToDateToTrack(toDate);
    setDateRangeDefaultValue([
      moment(new Date(fromDateToTrack)).subtract(1, "days"),
      moment(new Date(toDateToTrack)).subtract(1, "days"),
    ]);
  };
  const setNextDate = () => {
    let fromDate = new Date(
      new Date(fromDateToTrack).setDate(new Date(fromDateToTrack).getDate() + 1)
    );
    let toDate = new Date(
      new Date(toDateToTrack).setDate(new Date(toDateToTrack).getDate() + 1)
    );
    setFromDateToTrack(fromDate);
    setToDateToTrack(toDate);
    setDateRangeDefaultValue([
      moment(new Date(fromDateToTrack)).add(1, "days"),
      moment(new Date(toDateToTrack)).add(1, "days"),
    ]);
  };
  const handleDateRange = (dateArray) => {
    setFromDateToTrack(dateArray[0]._d);
    setToDateToTrack(dateArray[1]._d);
    setDateRangeDefaultValue(dateArray);
  };
  const handleToPickerChange = (value) => {
    setToDateToTrack(value);
  };
  const handleFromPickerChange = (value) => {
    setFromDateToTrack(value);
  };
  const getBehaviorEventsType = (array) => {
    setDriverBehaviorEventType(array);
  };
  const getStopEventsType = (array) => {
    setStopEventTypes(array);
  };

  const onSearch = (event) => {
    let filteredSearch = vehicles.filter((x) => {
      return x.toLowerCase().includes(event.target.value.toLowerCase());
    });
    setSearch(filteredSearch);
  };
  const [color, setColor] = useState("#366E93");
  const onVehicleSelect = (event) => {
    event.preventDefault();
    let element = event.target;
    if (event.target.tagName == "SPAN") {
      element = element.parentElement;
    }
    setColor("black");
    if (previouslySelectedVehicleListItem) {
      previouslySelectedVehicleListItem.style.backgroundColor = "#FFFFFF";
      previouslySelectedVehicleListItem.style.borderLeft = "none";
    }
    setPreviouslySelectedVehicleListItem(element);
    element.style.backgroundColor = "#f5f5f5";
    element.style.borderLeft = "10px solid #366E93";
    global.session.set("select-vehicle", element.outerText);
    if (element.outerText != "") {
      setSelectedVehicleNum(element.outerText);
      let vehiclesInfo = trucksInfo.filter(
        (x) => x.regi_no.toLowerCase() == element.outerText.toLowerCase()
      );
      if (vehiclesInfo && vehiclesInfo.length > 0) {
        setSelectedVehicle(vehiclesInfo[0]);
      }
    }
  };
  const setDistanceTravelledFunc = (value) => {
    setDistanceTravelled(value ? value.toFixed(2) + " KM" : "-");
  };
  const setRunningHoursFunc = (value) => {
    setRunningHours(value ? value : "-");
  };
  const getVehilcePositionTimestampFunc = (value) => {
    setVehilcePositionTimestamp(value);
  };
  const onSelectVehicle = (value) => {
    if (value && value.length > 0) {
      let vehicleNo = value;
      let vehiclesInfo = trucksInfo.filter(
        (x) =>
          x?.regi_no.toLowerCase().trim() ==
          vehicleNo[0]?.label.toLowerCase().trim()
      );
      if (vehiclesInfo && vehiclesInfo.length > 0) {
        // history.push({
        //     pathname: '/dashboard',
        //     search: '?vehicle=' + vehicleNo
        // })
        // setSelectedVehicleNum(vehicleNo);

        // window.location = "/dashboard?vehicle=" + vehicleNo[0]?.label;
        setSelectedVehicle(vehiclesInfo[0]);
      }
      setGetVehicle(value);
    }
  };
  let getProfileAccess = JSON.parse(sessionStorage.getItem("profile"));
  let getUserType = localStorage.getItem("userType")

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {hideHeader ? null : <Header ownerId={ownerId} />}

      <div className="card-container">
        {getUserType === "customer" && (
          <ArrowBack
            className="arrow"
            style={{ margin: 10 }}
            onClick={() => {
              history.push("./trip");
            }}
          />
        )}
        <Tabs type="card" style={{ padding: 5 }}>
          <TabPane
            tab={<TabButton assetNo="0" title="Tracking History" />}
            key="1"
            style={{ border: "1px solid #707070", background: "#F4F7F9" }}
          >
            {trucks.length > 0 ? (
              <div className={classes.content}>
                <div style={{ display: "none" }}>
                  <Paper
                    style={{
                      width: "100%",
                      height: winHeight - 65,
                      overflow: "auto",
                    }}
                  >
                    <div style={{ borderColor: "red" }}>
                      <TextField
                        style={{ padding: "10px", width: "200px" }}
                        variant="outlined"
                        placeholder="Search by Vehicle Number"
                        inputProps={{
                          style: { fontSize: 11, color: "#174A84" },
                        }}
                        onChange={onSearch}
                        InputProps={{
                          endAdornment: (
                            <SearchIcon
                              style={{ color: "#174A84", width: "17px" }}
                              onChange={onSearch}
                            />
                          ),
                        }}
                      />
                    </div>
                    <Divider style={{ margin: "10px" }} />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "left",
                        paddingLeft: 20,
                      }}
                    >
                      <img
                        src={truck}
                        style={{
                          width: "20px",
                          height: "20px",
                          marginTop: "2px",
                        }}
                      />
                      <Typography
                        style={{
                          color: "#366E93",
                          fontWeight: "bold",
                          marginLeft: "10px",
                        }}
                      >
                        All vehicles
                      </Typography>
                    </div>
                    <div>
                      {trucks.map((vehicleNo, index) => (
                        <li className={classes.list} index={index}>
                          <Button
                            style={{
                              color: "#366E93",
                              fontWeight: "bold",
                              textAlign: "left",
                              justifyContent: "flex-start",
                              alignItems: "flex-start",
                              paddingLeft: 20,
                            }}
                            className={{ selected: classes.active }}
                            index={index}
                            ref={index == 0 ? firstVehicleButtonRef : null}
                            color={color}
                            onClick={onVehicleSelect}
                            fullWidth
                          >
                            {vehicleNo}
                          </Button>
                        </li>
                      ))}
                    </div>
                  </Paper>
                </div>
                <div style={{ backgroundColor: "#F4F7F9", width: "100%" }}>
                  <div className={classes.middle}>
                    <div
                      style={{
                        color: "#366E93",
                        fontWeight: "bold",
                        float: "left",
                        position: "relative",
                        alignItems: "center",
                        height: "100%",
                        padding: 5,
                      }}
                    >
                      <AutoSelect
                        selectLabel="Select Vehicle"
                        onSelect={onSelectVehicle}
                        value={{
                          label: selectedVehicle?.regi_no,
                          value: selectedVehicle?.id,
                        }}
                        items={fields.trucks.options}
                        index={vehicleDropdownIndex}
                      />
                    </div>
                    <div
                      style={{
                        padding: 5,
                        float: "left",
                        position: "relative",
                      }}
                    >
                      <DriverBehaviorSelect
                        setBehaviorEventsType={getBehaviorEventsType}
                        items={driverBehaviorEvents}
                      />
                    </div>
                    <div
                      style={{
                        padding: 5,
                        float: "left",
                        position: "relative",
                      }}
                    >
                      <VehicleStops
                        selectLabel="Stop Events"
                        setStopEventsType={getStopEventsType}
                        items={stopEvents}
                      />
                    </div>
                    <div
                      style={{
                        padding: 5,
                        paddingLeft: 10,
                        alignItems: "center",
                        float: "left",
                        breakInside: "avoid",
                        width: 250,
                      }}
                    >
                      <Typography
                        style={{
                          color: "#366E93",
                          fontWeight: "bold",
                          fontSize: 15,
                        }}
                      >
                        {" "}
                        Distance Travelled: {distanceTravelled}
                      </Typography>
                      <Typography
                        style={{
                          color: "#366E93",
                          fontWeight: "bold",
                          fontSize: 15,
                        }}
                      >
                        {" "}
                        Running Hours: {runningHours}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        float: "left",
                        zIndex: 500,
                        width: winWidth > 500 ? 450 : 340,
                        position: "relative",
                        padding: 5,
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        color="primary"
                        aria-label="set previous date"
                        component="span"
                        onClick={setPreviousDate}
                      >
                        <ChevronLeft
                          className={classes.icon}
                          style={{
                            fontSize: 30,
                            color: "#FFFFFF",
                            backgroundColor: "#366E93",
                          }}
                        />
                      </IconButton>
                      <DateRangePicker
                        onDateSelect={handleDateRange}
                        value={dateRangeDefaultValue}
                      />
                      <IconButton
                        color="primary"
                        aria-label="set next date"
                        component="span"
                        onClick={setNextDate}
                      >
                        <ChevronRight
                          className={classes.icon}
                          style={{
                            fontSize: 30,
                            color: "#FFFFFF",
                            backgroundColor: "#366E93",
                          }}
                        />
                      </IconButton>
                    </div>
                    {vehilcePositionTimestamp != "" ? (
                      <div
                        style={{
                          padding: 5,
                          paddingLeft: 10,
                          paddingTop: 10,
                          alignItems: "center",
                          float: "right",
                          breakInside: "avoid",
                        }}
                      >
                        <Typography
                          style={{
                            color: "#366E93",
                            fontWeight: "bold",
                            fontSize: 15,
                          }}
                        >
                          {" "}
                          Tracking Time: {vehilcePositionTimestamp}
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {selectedVehicle === null ? (
                    <Map
                      // ref={mapRef}
                      pickupLocation={fields.pickupLocation}
                      dropoffLocation={""}
                      zoom={15}
                      waypoints={[]}
                      polyLine={[]}
                      height={mapHeight}
                      siteDetails={getSiteInfo}
                    />
                  ) : (
                    <TrackingMap
                      height={mapHeight}
                      SelectedVehicle={selectedVehicle}
                      fromDate={fromDateToTrack}
                      toDate={toDateToTrack}
                      getDistanceTravelled={setDistanceTravelledFunc}
                      getRunningHours={setRunningHoursFunc}
                      DriverBehaviorEventType={driverBehaviorEventType}
                      setVehilcePositionTimestampFunc={
                        getVehilcePositionTimestampFunc
                      }
                      StopEventTypes={stopEventTypes}
                      siteDetails={getSiteInfo}
                      key={
                        selectedVehiceNum +
                        "-" +
                        new Date(fromDateToTrack).getTime() +
                        "-" +
                        new Date(toDateToTrack).getTime()
                      }
                    />
                  )}
                </div>
              </div>
            ) : (
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  width: "100%",
                  textAlign: "center",
                  fontSize: 25,
                  fontFamily: "sans-serif",
                  color: "#366E93",
                }}
              >
                <Typography
                  style={{
                    color: "#366E93",
                    fontWeight: "bold",
                    marginLeft: "10px",
                  }}
                >
                  {errorMessage}
                </Typography>
              </div>
            )}
          </TabPane>
          <TabPane
            tab={<TabButton assetNo="1" title="Fuel History" />}
            key="2"
            style={{ border: "1px solid #707070", background: "#F4F7F9" }}
          >
            <FuelHistoryContent
              fuelVehicles={flVehicles}
              trucksInfo={trucksInfo}
              style={{ padding: 10 }}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
}
