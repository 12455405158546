import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { Grid, CircularProgress } from "@material-ui/core";
import * as Fields from "../../sharedComponents/index";
import { InputAdornment } from "@material-ui/core";
import _ from "lodash";
import VisibilityIcon from "@material-ui/icons/Visibility";
import AlertMessage from "../../components/alertmessage/AlertMessage";
import {
  logoImage as Logo,
  backgroundImage as Background,
} from "../../assets/index";
import { Link, Typography, Button } from "@material-ui/core";
import ValidateFields from "../../validations/validateFields";
import * as CONFIG from "../../config/GlobalConstants";
import { useLocation } from "react-router-dom";
import { parse } from "qs";
import { generateToken } from "../../firebase";import { useHistory } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import { RegistrationForm } from "../../register.js/registration";

var Service;
var Session;
const useStyles = makeStyles((theme) => ({
  img_style: {
    width: "250px",
    height: "107px",
    display: "block",
    margin: "auto",
  },
  paper_style: {
    padding: 20,
    width: 280,
    margin: "20px auto",
    borderRadius: 10,
  },
  alertBox: {
    padding: "0px",
  },
  forgot_password: {
    fontSize: "10pt",
    color: "#366E93",
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "#366E93",
    color: "#FFFFFF",
    textTransform: "none",
  },
  registernow: {
    fontSize: "10pt",
    color: "#366E93",
    fontWeight: "bold",
  },
  account: {
    fontSize: "10pt",
    color: "#777777",
  },
  bottom_fields: {
    textAlign: "center",
    marginTop: "5%",
    padding: "10px",
  },
}));
export default function Signin(props) {
  Service = global.service;
  Session = global.session;
  const formFields = {
    email: {
      name: "email",
      value: "",
      placeholder: "email or mobile number",
      errorMsg: "Please enter email or mobile number",
      validPattern: "EMAIL_MOBILE",
      validationRequired: true,
      isValid: true,
      type: "text",
      min: 0,
    },
    password: {
      name: "password",
      value: "",
      placeholder: "password",
      errorMsg: "Please enter password",
      validPattern: "PASSWORD",
      validationRequired: true,
      isValid: true,
      type: "password",
      min: 0,
    },
    userType: {
      name: "userType",
      label: "User Type",
      topLabel: true,
      value: { label: "Fleet Operator", value: "2" },
      options: [
        {
          label: "Fleet Operator",
          value: "2",
        },
        {
          label: "Customer",
          value: "3",
        },
        {
          label: "Service Provider",
          value: "4",
        },
      ],
      // placeholder: "Enter company name",
      // errorMsg: "Please select user type",
      // validPattern: "SPECIAL_CHARS_DESC",
      validationRequired: true,
      // isValid: true
    },
  };
  const classes = useStyles();
  const [fields, setFields] = useState(_.cloneDeep(formFields));
  const [spinner, setSpinner] = useState(false);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;
  let { search } = useLocation();
  const inputChangeHandler = (value, name) => {
    let newFields = _.cloneDeep(fields);
    newFields[name]["value"] = value;
    newFields[name]["isValid"] = true;
    if (value.length === 0 && newFields[name]["validationRequired"]) {
      newFields[name]["isValid"] = false;
    }
    setFields(newFields);
  };
  // const token = await generateToken()
  let token = sessionStorage.getItem(`Gcm_token`)

  const autoCompleteChangeHandler = (value, name) => {
    let newFields = _.cloneDeep(fields);
    newFields[name]["value"] = { label: value?.value, value: value?.value };
    setFields(newFields);
  };
  let validateFields = new ValidateFields();
  const handleLogin = (event) => {
    event.preventDefault();
    let dataObj = _.cloneDeep(fields);
    const dataStatus = validateFields.validateFieldsData(dataObj);
    const data = {
      ...dataObj,
      gcmToken:token
    }
    setSpinner(true);
    Service.userLogin(data)
      .then((res) => {
        let flag = false;
        if (res.status) {
          const userTypes = res.profile.userType;
          let selecion =
            fields?.userType.value?.value === "2"
              ? "owner"
              : fields?.userType.value?.value === "4"
              ? "provider"
              : "customer";
          switch (selecion) {
            case "customer":
              for (let i = 0; i < userTypes.length; i++) {
                const user = userTypes[i];
                if (user == "customer") {
                  flag = true;
                }
              }
              if (flag) {
                setSpinner(false);
                res.profile.userType = "customer";
                sessionStorage.setItem("userType", "customer");
                localStorage.setItem("userType", "customer");
                Session.set("BearerToken", res.token);
                Session.set("profile", res.profile);
                window.location = "/Trip";
              } else {
                setSpinner(false);
                setAlertData({
                  open: true,
                  severity: CONFIG.ALERT_SEVERITY.error,
                  message: "Unable to Login",
                });
              }
              break;
            case "owner":
              for (let i = 0; i < userTypes.length; i++) {
                const user = userTypes[i];
                if (user == "owner") {
                  flag = true;
                }
              }
              if (flag) {
                setSpinner(false);
                res.profile.userType = "owner";
                sessionStorage.setItem("userType", "owner");
                localStorage.setItem("userType", "owner");
                Session.set("BearerToken", res.token);
                Session.set("profile", res.profile);
                window.location = "/myFleet";
              } else {
                setSpinner(false);
                setAlertData({
                  open: true,
                  severity: CONFIG.ALERT_SEVERITY.error,
                  message: "Unable to Login",
                });
              }
              break;
            case "provider":
              for (let i = 0; i < userTypes.length; i++) {
                const user = userTypes[i];
                if (user == "provider") {
                  flag = true;
                }
              }
              if (flag) {
                setSpinner(false);
                res.profile.userType = "provider";
                sessionStorage.setItem("userType", "provider");
                localStorage.setItem("userType", "provider");
                Session.set("BearerToken", res.token);
                Session.set("profile", res.profile);
                window.location = "/dashboard";
              } else {
                setSpinner(false);
                setAlertData({
                  open: true,
                  severity: CONFIG.ALERT_SEVERITY.error,
                  message: "Unable to Login",
                });
              }
              break;
          }
        } else {
          setSpinner(false);
          let newFields = _.cloneDeep(fields);
          newFields = dataStatus.data;
          setFields(newFields);
          setAlertData({
            open: true,
            severity: CONFIG.ALERT_SEVERITY.error,
            message: res.message,
          });
        }
      })
      .catch((error) => {
        setSpinner(false);
        let newFields = _.cloneDeep(fields);
        newFields = dataStatus.data;
        setFields(newFields);
        setAlertData({
          open: true,
          severity: CONFIG.ALERT_SEVERITY.error,
          message: error.message,
        });
      });
    // if (
    //   dataStatus.status &&
    //   fields?.userType.value?.value === "Fleet Operator"
    // ) {
    //   setSpinner(true);
    //   Service.userLogin(dataObj)
    //     .then((res) => {
    //       debugger;
    //       let flag = false;
    //       if (res.status) {
    //         const userTypes = res.profile.userType;
    //         let selecion =
    //           fields?.userType.value?.value === "Fleet Operator"
    //             ? "owner"
    //             : fields?.userType.value?.value === "Service Provider"
    //             ? "provider"
    //             : "customer";
    //         switch (selecion) {
    //           case "customer":
    //             for (let i = 0; i < userTypes.length; i++) {
    //               const user = userTypes[i];
    //               if (user == "customer") {
    //                 flag = true;
    //               }
    //             }
    //             if (flag) {
    //               setSpinner(false);
    //               res.profile.userType = "customer";
    //               console.log(res, "cust ressssssssss");
    //               Session.set("BearerToken", res.token);
    //               Session.set("profile", res.profile);
    //               window.location = "/Trip";
    //             } else {
    //               //show error
    //             }
    //             break;
    //           case "owner":
    //             for (let i = 0; i < userTypes.length; i++) {
    //               const user = userTypes[i];
    //               if (user == "owner") {
    //                 flag = true;
    //               }
    //             }
    //             if (flag) {
    //               setSpinner(false);
    //               res.profile.userType = "owner";
    //               console.log(res, "owner ressss");
    //               Session.set("BearerToken", res.token);
    //               Session.set("profile", res.profile);
    //               window.location = "/dashboard";
    //             } else {
    //                 setAlertData({
    //                   open: true,
    //                   severity: CONFIG.ALERT_SEVERITY.error,
    //                   message: "Unable to Login",
    //                 });
    //             }
    //             break;
    //         }

    //         // if(res.profile.userType.length === 1){
    //         //   if(res.profile.userType[0] === "owner"){
    //         //     setSpinner(false);
    //         //     res.profile.userType = 'owner'
    //         //     Session.set('BearerToken', res.token);
    //         //     Session.set('profile', res.profile);
    //         //     window.location = '/dashboard';
    //         //   }
    //         // }else if(res.profile.userType.length === 2){
    //         //   if(res.profile.userType[0] === "owner" || res.profile.userType[1] === "owner"){
    //         //     setSpinner(false);
    //         //     res.profile.userType = 'owner'
    //         //     Session.set('BearerToken', res.token);
    //         //     Session.set('profile', res.profile);
    //         //     window.location = '/dashboard';
    //         //   }
    //         // }else{
    //         //   setAlertData({
    //         //     open: true,
    //         //     severity: CONFIG.ALERT_SEVERITY.error,
    //         //     message: "Unable to Login",
    //         //   });
    //         // }
    //       } else {
    //         setSpinner(false);
    //         let newFields = _.cloneDeep(fields);
    //         newFields = dataStatus.data;
    //         setFields(newFields);
    //         setAlertData({
    //           open: true,
    //           severity: CONFIG.ALERT_SEVERITY.error,
    //           message: res.message,
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       setSpinner(false);
    //       let newFields = _.cloneDeep(fields);
    //       newFields = dataStatus.data;
    //       setFields(newFields);
    //       setAlertData({
    //         open: true,
    //         severity: CONFIG.ALERT_SEVERITY.error,
    //         message: error.message,
    //       });
    //     });
    // } else if (
    //   dataStatus.status &&
    //   fields?.userType.value?.value === "Customer"
    // ) {
    //   debugger;
    //   setSpinner(true);
    //   Service.userLogin(dataObj)
    //     .then((res) => {
    //       if (res.status) {
    //         // if(res.profile.userType.length === 1){
    //         //   if(res.profile.userType[0] === "customer"){
    //         //     setSpinner(false);
    //         //     res.profile.userType = 'customer'
    //         //     Session.set('BearerToken', res.token);
    //         //     Session.set('profile', res.profile);
    //         //     // window.location = 'https://app.suvegafleet.com/owner/my-vehicles';
    //         //     window.location = '/Trip'
    //         //   }
    //         // }else if(res.profile.userType.length === 2){
    //         //   if(res.profile.userType[0] === "customer" || res.profile.userType[1] === "customer"){
    //         //     setSpinner(false);
    //         //     res.profile.userType = 'customer'
    //         //     Session.set('BearerToken', res.token);
    //         //     Session.set('profile', res.profile);
    //         //     // window.location = 'https://app.suvegafleet.com/owner/my-vehicles';
    //         //     window.location = '/Trip'
    //         //   }
    //         // }else{
    //         //   setAlertData({
    //         //     open: true,
    //         //     severity: CONFIG.ALERT_SEVERITY.error,
    //         //     message: "Unable to Login",
    //         //   });
    //         // }
    //         setSpinner(false);
    //         // res.profile.userType = 'customer'
    //         Session.set("userType", "customer");
    //         Session.set("BearerToken", res.token);
    //         Session.set("profile", res.profile);
    //         // window.location = 'https://app.suvegafleet.com/owner/my-vehicles';
    //         window.location = "/Trip";
    //       } else {
    //         setSpinner(false);
    //         let newFields = _.cloneDeep(fields);
    //         newFields = dataStatus.data;
    //         setFields(newFields);
    //         setAlertData({
    //           open: true,
    //           severity: CONFIG.ALERT_SEVERITY.error,
    //           message: res.message,
    //         });
    //       }
    //     })
    //     .catch((error) => {
    //       setSpinner(false);
    //       let newFields = _.cloneDeep(fields);
    //       newFields = dataStatus.data;
    //       setFields(newFields);
    //       setAlertData({
    //         open: true,
    //         severity: CONFIG.ALERT_SEVERITY.error,
    //         message: error.message,
    //       });
    //     });
    // } else {
    //   let newFields = _.cloneDeep(fields);
    //   newFields = dataStatus.data;
    //   setFields(newFields);
    // }
  };
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };

  const handleLoginByToken = (path) => {
    setAlertData({
      open: true,
      severity: CONFIG.ALERT_SEVERITY.info,
      message: "verifying token...please wait",
    });
    Service.userLoginByToken()
      .then((response) => {
        if (response.status && fields?.userType.value?.value !== "3") {
          response.profile.userType = "owner";
          global.session.set("BearerToken", response.token);
          global.session.set("profile", response.profile);
          window.location = path;
        } else if (response.status && fields?.userType.value?.value === "3") {
          response.profile.userType = "customer";
          global.session.set("BearerToken", response.token);
          global.session.set("profile", response.profile);
          window.location = path;
        } else {
          global.session.remove("BearerToken");
          global.session.remove("profile");
          sessionStorage.remove("userType");
          setAlertData({
            open: true,
            severity: CONFIG.ALERT_SEVERITY.error,
            message: "Invalid URL",
          });
        }
      })
      .catch((err) => {
        global.session.remove("BearerToken");
        global.session.remove("profile");
        sessionStorage.remove("userType");
        setAlertData({
          open: true,
          severity: CONFIG.ALERT_SEVERITY.error,
          message: err.toString(),
        });
      });
  };
  let history = useHistory();
  const handlingForgotPassword = () => {
    window.location = "/forgot-password";
  };
  const handleRegistration = (fields, event) => {
    let typeId = fields.userType.value.value;
    localStorage.setItem("typeId", JSON.stringify(typeId));
    localStorage.removeItem("tempOwnerId");
    localStorage.removeItem("companyData");
    history.push("/registration");
  };

  useEffect(() => {
    const query = parse(props.location.search, {
      ignoreQueryPrefix: true,
    });
    if (query && query.token && query.token.length > 0) {
      let token = query.token.replace(/ /g, "+");
      global.session.set("BearerToken", token);
      if (query.header && query.header.length > 0 && query.header == "hide") {
        global.session.set("hideHeader", true);
      }
      let path = window.location.href
        .replace("token=", "")
        .replace(query.token, "")
        .trim();
      if (path.endsWith("?") || path.endsWith("&")) {
        path = path.substring(0, path.length - 1);
      }
      handleLoginByToken(path);
    }
  }, []);

  return (
    <div>
      {alertData.open ? (
        <div className={classes.alertBox}>
          <AlertMessage
            severity={alertData.severity}
            message={alertData.message}
            closeAlert={closeAlert}
          />
        </div>
      ) : null}
      <Paper
        style={{
          backgroundImage: `url(${Background})`,
          width: winWidth,
          height: winHeight,
          backgroundSize: "contain",
        }}
      >
        <div>
          <img src={Logo} className={classes.img_style} />
        </div>
        <div>
          <Paper elevation={10} className={classes.paper_style}>
            <h2>Sign In</h2>
            <form noValidate autoComplete="off">
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Fields.InputField
                    fullWidth={true}
                    fieldData={fields.email}
                    inputChangeHandler={inputChangeHandler}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Fields.InputField
                    fieldData={fields.password}
                    inputChangeHandler={inputChangeHandler}
                    endAdornment={
                      <InputAdornment style={{ backgroundColor: "#777777" }}>
                        <VisibilityIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            let newFields = _.cloneDeep(fields);
                            if (newFields.password.type === "password") {
                              newFields.password.type = "text";
                              setFields(newFields);
                            } else {
                              newFields.password.type = "password";
                              setFields(newFields);
                            }
                          }}
                        />
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Fields.AntSelectableSearchField
                    fullWidth={true}
                    fieldData={fields.userType}
                    autoCompleteChangeHandler={autoCompleteChangeHandler}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <div style={{ marginTop: -10, marginBottom: 50 }}>
                <div style={{ float: "right", marginTop: 5 }}>
                  <Link href="#" className={classes.forgot_password}>
                    {/* Forgot Password? */}
                  </Link>
                </div>
              </div>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                fullWidth={true}
                className={classes.button}
                onClick={handleLogin}
              >
                {spinner ? (
                  <CircularProgress size={24} color={"#FFFFFF"} />
                ) : (
                  "Sign In"
                )}
              </Button>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Link
                  style={{
                    marginTop: "10px",
                    fontSize: "15px",
                    color: "#366E93",
                  }}
                  onClick={handlingForgotPassword}
                >
                  Forgot Password
                </Link>
              </div>
              {/* <Typography className={classes.bottom_fields}>
                <label className={classes.account}> */}
              {/* Don't have an Account..? */}
              {/* </label>
                <Link href="#" className={classes.registernow}> */}
              {/* Register Now */}
              {/* </Link>
              </Typography> */}
              <Button
                style={{ marginTop: "10px" }}
                type="submit"
                color="primary"
                variant="contained"
                fullWidth={true}
                className={classes.button}
                onClick={(event) => handleRegistration(fields, event)}
              >
                Register Now
              </Button>
            </form>
          </Paper>
        </div>
      </Paper>
    </div>
  );
}
