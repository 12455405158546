import { Paper, makeStyles } from "@material-ui/core";
import AlertMessage from "../components/alertmessage/AlertMessage";
import * as Fields from "../sharedComponents";
import { Grid, CircularProgress } from "@material-ui/core";
import { Link, Typography, Button } from "@material-ui/core";
import * as CONFIG from "../config/GlobalConstants";
import { useHistory, useLocation } from "react-router-dom";

import {
  logoImage as Logo,
  backgroundImage as Background,
} from "../assets/index";
import { useEffect, useState } from "react";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  img_style: {
    width: "250px",
    height: "107px",
    display: "block",
    margin: "auto",
  },
  paper_style: {
    padding: 20,
    width: 280,
    margin: "20px auto",
    borderRadius: 10,
  },
  alertBox: {
    padding: "0px",
  },
  forgot_password: {
    fontSize: "10pt",
    color: "#366E93",
    fontWeight: "bold",
  },
  button: {
    backgroundColor: "#366E93",
    color: "#FFFFFF",
    textTransform: "none",
  },
  registernow: {
    fontSize: "10pt",
    color: "#366E93",
    fontWeight: "bold",
  },
  account: {
    fontSize: "10pt",
    color: "#777777",
  },
  bottom_fields: {
    textAlign: "center",
    marginTop: "5%",
    padding: "10px",
  },
}));

export function ForSuccessMessage() {
  const forRoutingLoginPage = (event) => {
    localStorage.removeItem("typeId");
    window.location = "/";
  };
  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;
  const history = useHistory();
  const [spinner, setSpinner] = useState(false);
  const classes = useStyles();
  return (
    <div>
      <Paper
        style={{
          backgroundImage: `url(${Background})`,
          width: winWidth,
          height: winHeight,
          backgroundSize: "contain",
        }}
      >
        <div>
          <img src={Logo} className={classes.img_style} />
        </div>
        <div>
          <Paper elevation={10} className={classes.paper_style}>
            <h5 style={{ color: "blue", textAlign: "center" }}>
              Thank you for registering with SuvegaFleet!
            </h5>
            <h6 style={{ textAlign: "center" }}>
              we will verify your details and send you a verification email in
              the next 24 hours
            </h6>
            <h6 style={{ textAlign: "center" }}>
              once you get the email please come back to complete your
              registration
            </h6>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth={true}
              className={classes.button}
              style={{ marginTop: "5px", marginBottom: "5px" }}
              onClick={forRoutingLoginPage}
            >
              {spinner ? (
                <CircularProgress size={24} color={"#FFFFFF"} />
              ) : (
                "Next"
              )}
            </Button>
          </Paper>
        </div>
      </Paper>
    </div>
  );
}
export function ForCustomerVerification() {
  const tempOwnerId = localStorage.getItem("tempOwnerId");
  let Service = global.service;
  let history = useHistory();
  let otpData = {
    otpCode: {
      name: "otpCode",
      topLabel: true,
      value: "",
      placeholder: "Enter Mobile OTP",
      errorMsg: "Please enter mobile code",
      validPattern: "SPECIAL_CHARS_DESC",
      validationRequired: true,
      isValid: true,
      min: 0,
    },
    emailCode: {
      name: "emailCode",
      topLabel: true,
      value: "",
      placeholder: "Enter Email OTP",
      errorMsg: "Please enter email code",
      validPattern: "SPECIAL_CHARS_DESC",
      validationRequired: true,
      isValid: true,
      min: 0,
    },
  };
  let classes = useStyles();
  let [sendingdata, setOtpdata] = useState(otpData);
  const [spinner, setSpinner] = useState(false);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;
  const [timer, setTimer] = useState(30);
  const inputChangeHandler = (value, name) => {
    let newFields = _.cloneDeep(sendingdata);
    newFields[name]["value"] = value;
    newFields[name]["isValid"] = true;
    if (value.length === 0 && newFields[name]["validationRequired"]) {
      newFields[name]["isValid"] = false;
    }
    setOtpdata(newFields);
  };
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };
  const forAppearingSuccessPage = () => {
    Service.customerValidateOtp({
      id: tempOwnerId,
      email_otp: sendingdata.emailCode.value,
      mobile_otp: sendingdata.otpCode.value,
    }).then((response) => {
      if (response.status == true) {
        if (response.isEmailOtpValid && response.isSmsOtpValid) {
          setAlertData({
            open: true,
            message: response.message,
            severity: "success",
          });
          global.window.location.pathname = "./success";
          history.push("./success");
        } else if (!response.isEmailOtpValid) {
          setAlertData({
            open: true,
            message: "Please enter correct email otp",
            severity: "error",
          });
        } else if (!response.isSmsOtpValid) {
          setAlertData({
            open: true,
            message: "Please enter correct mobile otp",
            severity: "error",
          });
        }
      } else {
        setAlertData({
          open: true,
          message: response.message,
          severity: "error",
        });
      }
    });
  };
  const forReSendingOtp = () => {
    Service.resendVCode({ id: tempOwnerId }).then((response) => {
      if (response.status == true) {
        setAlertData({
          open: true,
          message: response.message,
          severity: "success",
        });
      } else {
        setAlertData({
          open: true,
          message: response.message,
          severity: "error",
        });
      }
    });
    setTimer(30);
  };
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  return (
    <div>
      <Paper
        style={{
          backgroundImage: `url(${Background})`,
          width: winWidth,
          height: winHeight,
          backgroundSize: "contain",
        }}
      >
        <div>
          <img src={Logo} className={classes.img_style} />
        </div>
        <div>
          <Paper elevation={10} className={classes.paper_style}>
            {alertData.open ? (
              <div className={classes.alertBox}>
                <AlertMessage
                  severity={alertData.severity}
                  message={alertData.message}
                  closeAlert={closeAlert}
                />
              </div>
            ) : null}
            <h5 style={{ color: "blue", textAlign: "center" }}>Verification</h5>
            <form noValidate autoComplete="off">
              <Grid item xs={12}>
                <Fields.InputField
                  fullWidth={true}
                  fieldData={sendingdata.emailCode}
                  inputChangeHandler={inputChangeHandler}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <Fields.InputField
                  fullWidth={true}
                  fieldData={sendingdata.otpCode}
                  inputChangeHandler={inputChangeHandler}
                  variant="outlined"
                />
              </Grid>
            </form>
            {timer > 0 ? (
              <p style={{ fontWeight: "bold", marginTop: "10px" }}>
                Resend OTP in <span style={{ color: "red" }}>{timer}</span>{" "}
                seconds
              </p>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="outlined"
                  type="submit"
                  style={{
                    textTransform: "none",
                    color: "#F15F4C",
                    marginTop: "15px",
                  }}
                  onClick={forReSendingOtp}
                >
                  Resend Code
                </Button>
              </div>
            )}
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth={true}
              className={classes.button}
              style={{ width: "100%", marginTop: "15px", marginBottom: "20px" }}
              onClick={forAppearingSuccessPage}
            >
              {spinner ? (
                <CircularProgress size={24} color={"#FFFFFF"} />
              ) : (
                "Verify"
              )}
            </Button>
          </Paper>
        </div>
      </Paper>
    </div>
  );
}
export function OtpVerification() {
  const location = useLocation();
  const tempOwnerId = localStorage.getItem("tempOwnerId");
  const storedApiData = localStorage.getItem("typeId");
  let typeId = storedApiData ? JSON.parse(storedApiData) : null;
  let Service = global.service;
  let history = useHistory();
  let otpData = {
    otpCode: {
      name: "otpCode",
      // label:"Number of Vehicles",
      topLabel: true,
      value: "",
      placeholder: "Enter Four Degits Code",
      errorMsg: "Please enter code",
      validPattern: "SPECIAL_CHARS_DESC",
      validationRequired: true,
      isValid: true,
      min: 0,
    },
  };
  let classes = useStyles();
  let [sendingdata, setOtpdata] = useState(otpData);
  const [spinner, setSpinner] = useState(false);
  const [alertData, setAlertData] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;
  const [timer, setTimer] = useState(30);
  const inputChangeHandler = (value, name) => {
    let newFields = _.cloneDeep(sendingdata);
    newFields[name]["value"] = value;
    newFields[name]["isValid"] = true;
    if (value.length === 0 && newFields[name]["validationRequired"]) {
      newFields[name]["isValid"] = false;
    }
    setOtpdata(newFields);
  };
  const closeAlert = () => {
    let alert = _.cloneDeep(alertData);
    alert.open = false;
    alert.severity = "";
    alert.message = "";
    setAlertData(alert);
  };
  const forAppearingSuccessPage = () => {
    Service.smsVerifyCode({
      id: tempOwnerId,
      sms_vcode: sendingdata.otpCode.value,
    }).then((response) => {
      if (response.status == true) {
        const companyInfo = localStorage.getItem("companyData");
        let companyDataObj = JSON.parse(companyInfo);
        if (companyInfo !== null && companyInfo !== undefined) {
          Service.insertCompanyInformation(companyDataObj).then((res) => {
            if (res.status == true) {
              setAlertData({
                open: true,
                message: res.message,
                severity: "success",
              });
              global.window.location.pathname = "./success";
              history.push("./success");
            } else {
              setAlertData({
                open: true,
                message: res.message,
                severity: "error",
              });
            }
          });
        } else {
          global.window.location.pathname = "./success";
          history.push("./success");
          setAlertData({
            open: true,
            message: response.message,
            severity: "success",
          });
        }
      } else {
        setAlertData({
          open: true,
          message: response.message,
          severity: "error",
        });
      }
    });
  };
  const forReSendingOtp = () => {
    Service.resendVCode({ id: tempOwnerId }).then((response) => {
      if (response.status == true) {
        setAlertData({
          open: true,
          message: response.message,
          severity: "success",
        });
      } else {
        setAlertData({
          open: true,
          message: response.message,
          severity: "error",
        });
      }
    });
    setTimer(30);
  };
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  return typeId == 3 || typeId == 4 ? (
    <ForCustomerVerification />
  ) : (
    <div>
      <Paper
        style={{
          backgroundImage: `url(${Background})`,
          width: winWidth,
          height: winHeight,
          backgroundSize: "contain",
        }}
      >
        <div>
          <img src={Logo} className={classes.img_style} />
        </div>
        <div>
          <Paper elevation={10} className={classes.paper_style}>
            {alertData.open ? (
              <div className={classes.alertBox}>
                <AlertMessage
                  severity={alertData.severity}
                  message={alertData.message}
                  closeAlert={closeAlert}
                />
              </div>
            ) : null}
            <h5 style={{ color: "blue" }}>Enter Verification Code</h5>
            <p4>A 4-Degit Code Has Been Sent On Your Phone</p4>
            <form noValidate autoComplete="off">
              <Grid item xs={12}>
                <Fields.InputField
                  fullWidth={true}
                  fieldData={sendingdata.otpCode}
                  inputChangeHandler={inputChangeHandler}
                  variant="outlined"
                />
              </Grid>
            </form>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth={true}
              className={classes.button}
              style={{ width: "100%", marginTop: "20px", marginBottom: "20px" }}
              onClick={forAppearingSuccessPage}
            >
              {spinner ? (
                <CircularProgress size={24} color={"#FFFFFF"} />
              ) : (
                "Done"
              )}
            </Button>
            {timer > 0 ? (
              <p style={{ fontWeight: "bold" }}>
                Resend OTP in <span style={{ color: "red" }}>{timer}</span>{" "}
                seconds
              </p>
            ) : (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="outlined"
                  type="submit"
                  style={{ textTransform: "none", color: "#F15F4C" }}
                  onClick={forReSendingOtp}
                >
                  Resend Code
                </Button>
              </div>
            )}
          </Paper>
        </div>
      </Paper>
    </div>
  );
}
