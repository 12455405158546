import React,{useState} from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Menu, Dropdown } from "antd";
import { useHistory } from "react-router-dom";

const DropDownMenu = (props) => {
  const history = useHistory();
  const [triptype,setTriptype] = useState(false)
  const handleMenuItemClick = (option) => {
    if (option.type === "button") {
      props.buttonClickHandler(option);
      // return 
    }
    if (option.value) {
      history.push({ pathname: option.value, state: props.data });
      // return
    }
 
  };
  const menu = (
    <Menu>
      {props?.options?.map((opt, i) => (
        <Menu.Item
          key={i}
          onClick={() => {
            handleMenuItemClick(opt);
          }}
          style={{
            fontSize: "1rem",
            fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
            fontWeight: 400
          }}
        >
          {opt.label}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <div>
      <Dropdown overlay={menu}>
        <MoreVertIcon />
      </Dropdown>
    </div>
  );
};

export default DropDownMenu;
