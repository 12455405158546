import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  LayersControl,
  Circle,
  Polygon
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import * as L from "leaflet";
import { Button } from "@material-ui/core";
import {
  redMarkerImage,
  orangeMarkerImage,
  blueMarkerImage,
} from "../../../../assets/index";
import RouteMachine from "./RoutingMachine";
import { geocodeByLatLng } from "react-google-places-autocomplete";

const Map = (props, ref) => {
  const [route, setRoute] = useState(false);
  const [wpRefs, setwpRefs] = useState([]);
  const [center, setCenter] = useState([20.5937, 78.9629]);
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(5);
  const [pickFly, setPickFly] = useState(false);
  const [dropFly, setDropFly] = useState(false);
  const [index, setIndex] = useState(null);
  const [wpFly, setWpFly] = useState(false);
  useEffect(() => {}, [
    props.waypoints.filter((wp) => wp.location.lat && wp.location.lng).length,
  ]);
  useEffect(() => {
    setwpRefs((wpRefs) =>
      Array(
        props.waypoints.filter((wp) => wp.location.lat && wp.location.lng)
          .length
      )
        .fill()
        .map((_, i) => wpRefs[i] || React.createRef())
    );
  }, [
    props.waypoints.filter((wp) => wp.location.lat && wp.location.lng).length,
  ]);
  const pickRef = useRef(null);
  const dropRef = useRef(null);
  const pickeventHandler = useMemo(() => ({
    dragend() {
      const pickLoc = pickRef.current;
      if (pickLoc != null) {
        let latLng = pickLoc.getLatLng();
        geocodeByLatLng({ lat: latLng.lat, lng: latLng.lng })
          .then((res) => {
            props.setPickup({ lat: latLng.lat, lng: latLng.lng }, res);
            setRoute(false);
          })
          .catch(() => {});
      }
    },
  }));
  const dropeventHandler = useMemo(() => ({
    dragend() {
      const dropLoc = dropRef.current;
      if (dropLoc != null) {
        let latLng = dropLoc.getLatLng();
        geocodeByLatLng({ lat: latLng.lat, lng: latLng.lng })
          .then((res) => {
            props.setDropoff({ lat: latLng.lat, lng: latLng.lng }, res);
            setRoute(false);
          })
          .catch(() => {});
      }
    },
  }));
  const wpDragHandler = (i) => {
    const wploc = wpRefs[i].current;
    if (wploc != null) {
      let latLng = wploc.getLatLng();
      geocodeByLatLng({ lat: latLng.lat, lng: latLng.lng })
        .then((res) => {
          props.setWp({ lat: latLng.lat, lng: latLng.lng }, res, i);
          setRoute(false);
        })
        .catch(() => {});
    }
  };
  const flyToLoc = (loc, name, i) => {
    setCenter([loc.lt, loc.ln]);
    map.flyTo([loc.lt, loc.ln], 16);
    map.on("zoomend", () => {
      if((i || i === 0) && name === "location"){
        setIndex(i);
        setWpFly(true);
      }
      if (name === "pickupLocation") {
        setPickFly(true);
      }
      if (name === "dropoffLocation") {
        setDropFly(true);
      }
    });
  };
  useImperativeHandle(ref, () => {
    return { setRoute: setRoute, route: route, flyToLoc: flyToLoc };
  });
  return (
    <div ref={ref}>
      <div style={{ marginBottom: "10px" }}>
        {props.pickupLocation.lat &&
        props.pickupLocation.lng &&
        props.dropoffLocation.lat &&
        props.dropoffLocation.lng ? (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setRoute(true);
            }}
          >
            Get Route
          </Button>
        ) : null}
      </div>
      <MapContainer
        center={center}
        zoom={zoom}
        scrollWheelZoom={true}
        style={{ width: "100%", height: props.height }}
        whenCreated={(map) => setMap(map)}
      >
        <LayersControl position="topright">
          <LayersControl.BaseLayer checked={false} name="Satellite">
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}"
              subdomains={["mt0", "mt1", "mt2", "mt3"]}
            />
          </LayersControl.BaseLayer>
          <LayersControl.BaseLayer checked name="Terrain">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </LayersControl.BaseLayer>
          {props.pickupLocation.lat && props.pickupLocation.lng ? (
            <Marker
              position={[props.pickupLocation.lat, props.pickupLocation.lng]}
              icon={L.icon({
                iconUrl: blueMarkerImage,
                iconSize: new L.Point(30, 30),
                iconAnchor: [15, 30],
              })}
              draggable={true}
              ref={pickRef}
              eventHandlers={pickeventHandler}
            >
              <Popup>{props.pickupLocation.value}</Popup>
              {pickFly ? (
                <Circle
                  center={{
                    lat: props.pickupLocation.lat,
                    lng: props.pickupLocation.lng,
                  }}
                  fillColor="blue"
                  radius={200}
                />
              ) : null}
            </Marker>
          ) : null}
          {props.waypoints
            .filter((wp) => wp.location.lat && wp.location.lng)
            .map((wp, i) => (
              <Marker
                position={[wp.location.lat, wp.location.lng]}
                icon={L.icon({
                  iconUrl: orangeMarkerImage,
                  iconSize: new L.Point(30, 30),
                  iconAnchor: [15, 30],
                })}
                key={i}
                draggable={true}
                ref={wpRefs[i]}
                eventHandlers={{
                  dragend: () => {
                    wpDragHandler(i);
                  },
                }}
              >
                <Popup>{wp.location.value}</Popup>
                <Tooltip
                  direction="bottom"
                  offset={[0, 0]}
                  opacity={1}
                  permanent
                >
                  Waypoint {props.sequenceNumber ? props.sequenceNumber : i + 1}
                </Tooltip>
                {index === i && wpFly ? (
                  <Circle
                    center={{
                      lat: wp.location.lat,
                      lng: wp.location.lng,
                    }}
                    fillColor="blue"
                    radius={200}
                  />
                ) : null}
              </Marker>
            ))}
          {props.dropoffLocation.lat && props.dropoffLocation.lng ? (
            <Marker
              position={[props.dropoffLocation.lat, props.dropoffLocation.lng]}
              icon={L.icon({
                iconUrl: redMarkerImage,
                iconSize: new L.Point(30, 30),
                iconAnchor: [15, 30],
              })}
              draggable={true}
              ref={dropRef}
              eventHandlers={dropeventHandler}
            >
              <Popup>{props.dropoffLocation.value}</Popup>
              {dropFly ? (
                <Circle
                  center={{
                    lat: props.dropoffLocation.lat,
                    lng: props.dropoffLocation.lng,
                  }}
                  fillColor="blue"
                  radius={200}
                />
              ) : null}
            </Marker>
          ) : null}
          {props.siteDetails && props.siteDetails.length > 0 &&
            props.siteDetails.map((i, ind) => {
              return i?.geoFenceType === "circle" ? (
                <>
                  <Marker
                    position={[i?.position[0], i?.position[1]]}
                    icon={L.icon({
                      iconUrl: i?.siteType === 4
                      ? blueMarkerImage
                      : orangeMarkerImage,
                      iconSize: new L.Point(30, 30),
                      iconAnchor: [15, 30],
                    })}
                    key={ind}
                  >
                    {/* <Popup>{`${
                      i?.siteType === 4 ? "Loading Site" : "Unloading Site"
                    }: ${i?.address}`}</Popup> */}
                    <Tooltip style={{ borderRadius: 15 }}>
                            <h1
                              style={{
                                textAlign: "center",
                                fontSize: 14,
                                fontWeight: 600,
                                color: i?.siteType === 4 ? "green" : "orange",
                              }}
                            >
                              {i?.siteType === 4
                                ? "Loading Site"
                                : "Unloading Site"}
                            </h1>
                            <p>{`Name: ${i?.siteName}`}</p>
                            <p style={{}}>{`Address: ${i?.address}`}</p>
                          </Tooltip>
                  </Marker>
                  <Circle
                    center={{
                      lat: i?.position[0],
                      lng: i?.position[1],
                    }}
                    height={"350px"}
                    fillColor="blue"
                    radius={i?.radius}
                  />
                </>
              ) : (
                <>
                  <Marker
                    position={[i?.position[0], i?.position[1]]}
                    icon={L.icon({
                      iconUrl: i?.siteType === 4
                      ? blueMarkerImage
                      : orangeMarkerImage,
                      iconSize: new L.Point(30, 30),
                      iconAnchor: [15, 30],
                    })}
                    key={ind}
                  >
                    {/* <Popup>{`${
                      i?.siteType === 4 ? "Loading Site" : "Unloading Site"
                    }: ${i?.address}`}</Popup> */}
                    <Tooltip style={{ borderRadius: 15 }}>
                            <h1
                              style={{
                                textAlign: "center",
                                fontSize: 14,
                                fontWeight: 600,
                                color: i?.siteType === 4 ? "green" : "orange",
                              }}
                            >
                              {i?.siteType === 4
                                ? "Loading Site"
                                : "Unloading Site"}
                            </h1>
                            <p>{`Name: ${i?.siteName}`}</p>
                            <p style={{}}>{`Address: ${i?.address}`}</p>
                          </Tooltip>
                  </Marker>
                  <Polygon
                    pathOptions={{ color: "purple" }}
                    positions={i?.polygon}
                  />
                </>
              );
            })}
          {/* {props.polyLine.length > 1 ? (
          <Polyline
            pathOptions={{ color: "lime" }}
            positions={props.polyLine}
          />
        ) : null} */}
          {route ? (
            <RouteMachine
              pickupLocation={props.pickupLocation}
              dropoffLocation={props.dropoffLocation}
              waypoints={props.waypoints.filter(
                (wp) => wp.location.lat && wp.location.lng
              )}
            />
          ) : null}
        </LayersControl>
      </MapContainer>
    </div>
  );
};

export default forwardRef(Map);
